import {createAction, props} from '@ngrx/store';
import { Manager } from '../models/manager.model';

export enum ManagersActions {
  LOAD_MANAGERS = "[Managers] Load Managers",
  COMPANIES_LOADED = "[Managers] Managers loaded"
}

export const loadManagers = createAction(
  ManagersActions.LOAD_MANAGERS
);
export const managersLoaded = createAction(
  ManagersActions.COMPANIES_LOADED,
  props<{ managers: Manager[] }>()
);
