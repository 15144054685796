<!-- Terms of Service -->
<h2 mat-dialog-title>
  <b>PAYQUAD RESIDENT TERMS OF USE</b>

  <button
    class="no-print"
    mat-icon-button
    [printTitle]="'PAYQUAD RESIDENT TERMS OF USE'"
    [matTooltip]="'Print' | translate"
    printSectionId="termsPrint"
    [useExistingCss]="true"
    [printDelay]="100"
    [clickDelay]="100"
    (click)="printOpen()"
    (printClose)="printClose()"
    *ngIf="!isCordova"
    ngxPrint
  >
    <mat-icon svgIcon="pq:printer"></mat-icon>
  </button>
</h2>

<mat-dialog-content id="termsPrint">
  <h3 class="print print-header">PAYQUAD RESIDENT TERMS OF USE <br /></h3>

  <p>
    These resident terms of use (the "<strong>Agreement</strong>") are between
    the person or entity accepting them ("<strong>you</strong>",
    "<strong>your</strong>" or "<strong>Resident</strong>") and PayQuad
    SolutionsInc. ("<strong>PayQuad</strong>" or "<strong>we</strong>").
  </p>
  <p>
    This is a legally binding Agreement, please read it carefully before
    accepting. There's a glossary in section 21 at the end of this Agreement
    that gives definitions of the words that are capitalized.
  </p>
  <ol>
    <li>
      <p><strong>Acceptance.</strong></p>
    </li>
    <p>
      This Agreement is between you and PayQuad and takes effect by way of
      electronic acceptance by you by clicking "I agree" or some similar
      language at
      <a href="http://www.payquad.com/">www.payquad.com</a>
      (the"<strong>Site</strong>"). We'll call the date of your acceptance, the
      "<strong>Effective Date</strong>".
    </p>
    <li>
      <p><strong>Services.</strong></p>
    </li>
    <p>
      PayQuad agrees to provide the Services according to this Agreement. You
      can use the Services only for Properties and Units where you're the party
      to a Resident Agreement (such as a lease where you are a tenant).
    </p>
    <li>
      <p>
        <strong
          >Collection, Use, Storage And Disclosure Of Personal
          Information.</strong
        >
        YOUR PRIVACY IS OF PARAMOUNT IMPORTANCE TO US.
      </p>
    </li>
    <p>
      YOU AUTHORIZE PAYQUAD TO COLLECT, PROCESS, STORE AND DISCLOSE YOUR (I)
      NAME; (II) E-MAIL ADDRESS; (III) RESIDENTIAL ADDRESS; (IV) BANK ACCOUNT
      INFORMATION AND OR CREDIT CARD INFORMATION; (V) TERMS OF RESIDENT
      AGREEMENT; AND (VI) INFORMATION CONCERNING PAYMENTS MADE UNDER RESIDENT
      AGREEMENT (BEING "<strong>RESIDENT DATA</strong>") FOR THE PURPOSE OF (A)
      INVITING YOU TO ACCEPT A PAD AUTHORIZATION; (B) ASSISTING LANDLORD IN
      DEBITING YOUR DESIGNATED ACCOUNT RESIDENT FEES PAYABLE; (C) INFORMING
      LANDLORD OF RESIDENT FEES PAID; (D) CAUSING RESIDENT FEES TO BE SETTLED BY
      ITS PROCESSOR TO LANDLORD; (E) PROVIDING REPORTING TO RESIDENT AND
      LANDLORD ON ALL OF (A), (B), (C) AND (D); AND (F) PROVIDE THE SERVICES TO
      YOU.
    </p>
    <p>
      YOU ALLOW PAYQUAD TO E-MAIL YOU OR MAIL YOU IN ORDER TO OFFER RELATED
      PRODUCTS OR SERVICES IN ADDITION TO THOSE CONTEMPLATED IN THIS AGREEMENT.
    </p>
    <p>
      RESIDENT DATA COLLECTED BY PAYQUAD IS SUBJECT TO PAYQUAD'S PRIVACY POLICY,
      <strong>LINKED</strong>
      <a href="https://payquad.com/privacy-policy-terms/"><strong>HERE</strong></a>
      AND ALSO AVAILABLE AT THE SITE. PAYQUAD'S PRIVACY POLICY IS INCORPORATED
      BY REFERENCE AS IF SET FORTH IN FULL HEREIN.
    </p>
    <p>
      By accepting this Agreement you are giving permission to the
      Landlord/Property Manager to release the information to PayQuad needed to
      setup and administer your online account. Any new information/data that
      will arise from using the Services can be mutually shared by the
      Landlord/Property Manager and PayQuad to administer your account.
    </p>
    <li>
      <p><strong>Account.</strong></p>
    </li>
    <p>
      PayQuad will allow you to create a unique and private Account accessible
      through the Service and allow you to access codes for the Account. The
      Account is a record of Resident Transactions and Service Fees. You may not
      disclose the access codes or permit any third party to use them. Any such
      disclosure or permission shall entail a penalty of forfeiture of the
      Account in entirety. Without limiting the penalty on you in the preceding
      sentence, you assume full responsibility for your use of the Account and
      the access codes; you agree to indemnify PayQuad for any and all claims,
      losses or other liabilities arising therefrom. Except as required to
      deliver the Services or as otherwise required by law, PayQuad shall not
      grant any third party, other than the Landlord, access to your Account.
    </p>
    <li>
      <p><strong>Prohibited Activities.</strong></p>
    </li>
    <p>
      It is forbidden to use the Services to, directly or indirectly, knowingly
      or unknowingly assist in any illegal activity or any Prohibited Activity.
      Please take a moment to read the list of Prohibited Activities in the
      Glossary below. You may not use the Services in respect of any Property
      that is outside of the Territory.
    </p>
    <li>
      <p><strong>Prohibited Residents.</strong></p>
    </li>
    <p>
      The following Persons are prohibited from using the Services: (i) Persons
      who appear on Canada's <i>Anti-Terrorism Act</i>, Listed Entities list;
      (ii) Persons who are less than 18 years of age; and (iii) Persons, or
      their Affiliates who have been parties to an agreement with PayQuad that
      has been terminated for cause.
    </p>
    <li>
      <p><strong>Your Obligations.</strong></p>
    </li>
    <p>
      General representations. You represent and warrant to PayQuad that: (i)
      you are the Resident, user, beneficiary or person responsible for
      obligations to the Landlord under the Resident Agreement for the Unit;
      (ii) you have full authority to enter into this Agreement and that all
      necessary approvals have been obtained to enter into this Agreement; (iii)
      you are willing, capable and experienced to perform all of its obligations
      and services as provided for and contemplated by this Agreement; and (iv)
      as either element of a Resident Agreement or separately, prior to paying
      by PAD, you have an executed PAD Authorization with Landlord authorizing
      the Landlord to collect Resident Fees by PAD through the Service. You may
      also have executed a PAD Authorization together with accepting this
      Agreement.
    </p>
    <p>
      Service Fees. You shall pay all Service Fees when due. In the event that a
      Transaction fails due to insufficient funds or any other reason, a fee may
      be automatically added to the Service Fees that you agree to pay, or
      charged directly by your management company. Additional fees may also be
      assessed by your bank.
    </p>
    <p>
      Resident Agreement or PAD Termination. You shall notify PayQuad
      immediately of termination of any Resident Agreement or any PAD
      Authorization. If we haven't been given notice of the termination of
      either, we will assume that they are both still in effect.
    </p>
    <p>
      Accuracy of Information. You are liable for the accuracy or completeness
      of information provided to Landlord or PayQuad. For example purposes only,
      if you provide PayQuad with an incorrect Designated Account, PayQuad shall
      not be liable for Resident Fee payments settled from such incorrect
      account; you assume sole and exclusive responsibility for such errors.
      Where there is a difference between a blank cheque that you give us and
      other bank account information that you give us at the same time, we will
      rely on the blank cheque as a source of Designated Account information.
    </p>
    <p>
      Refunds, cancellations and charge backs. If the Transaction is completed
      in accordance with the terms of this Agreement, Resident accepts and
      agrees that no refunds, cancellations or charge backs are permitted
      through the Service, except as set out in Section 11 below. In the event
      that a payment is refunded, cancelled or charged back by Resident's Card
      issuing bank or another person, PayQuad is not obligated to return any of
      the associated Service Fees, irrespective of the reason for such refund,
      cancellation or charge back. If the Transaction is processed in accordance
      wit the terms of this Agreement, Resident agrees not to request a refund,
      cancellation or charge back of Service Fees from Resident's Card issuing
      bank or any other person. Resident will need to work with the Landlord for
      any refunds.
    </p>
    <p>
      Electronic Signatures.If you choose to use the electronic signature
      execution functionality when signing a document, you agree that such
      signature will be as valid as handwritten signatures and considered
      originals to the extent allowed by applicable law.
    </p>
    <li>
      <p><strong>PayQuad Obligations.</strong></p>
    </li>
    <p>
      Representations. PayQuad hereby represents and warrants to you that it is
      duly organized, validly existing and in good standing under the laws of
      Canada; and it has the authority to execute and deliver this Agreement.
    </p>
    <p>
      PayQuad shall not intentionally or knowingly violate any Applicable Laws.
    </p>
    <p>
      Subject to the PAD Authorization, PayQuad shall debit the Designated
      Account for Resident Fees in varying amounts throughout the Term of this
      Agreement.
    </p>
    <p>
      Reporting. PayQuad shall provide you with reports as to Transactions and
      Service Fees under the Account.
    </p>
    <p>
      No Representations. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
      PAYQUAD EXPRESSLY DISCLAIMS ANY IMPLIED WARRANTIES AND CONDITIONS,
      INCLUDING ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, AS WELL AS ANY WARRANTIES
      THAT THE SERVICES PROVIDED BY PAYQUAD OR THAT THE OPERATION OF THE
      SERVICES WILL BE INTERRUPTION OR ERROR FREE. PAYQUAD DOES NOT REPRESENT OR
      WARRANT THAT THE PAYQUAD SERVICES WILL BE AVAILABLE, ACCESSIBLE,
      UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE, OR ENTIRELY ERROR-
      FREE.
    </p>
    <li>
      <p><strong>PAD Transaction Settlement.</strong></p>
    </li>
    <p>
      As agent for Landlord, or in its own capacity, as per the PAD
      Authorization, PayQuad has the right to use its processor to debit
      Resident Fees payable under the Resident Agreement from the Designated
      Account. PayQuad shall then cause its processor to settle such funds to
      Landlord. PayQuad has been mandated by Landlord to present to you a PAD
      Authorization for acceptance through the Site.
    </p>
    <p>
      Refunding, returning or reversing Transactions. PayQuad has no obligation
      to refund, return or reverse any Transaction; any and all refunds, returns
      or reversals of Transactions that Landlord wishes to carry out shall be
      the sole and exclusive responsibility of Landlord, provided that PayQuad
      may, at its sole discretion assist in such transactions at its sole and
      absolute discretion.
    </p>
    <p>
      Where PayQuad has settled Resident Fees to Landlord and such Resident Fees
      are required to be returned to Resident on account of the deficiency of a
      PAD Authorization or for any other reason, Resident shall look to Landlord
      alone and not to PayQuad for reimbursement of all such Resident Fees.
    </p>
    <p>
      Receipt. PayQuad may, but is not required to, deliver to each Resident a
      Receipt, in the name of Landlord, for each amount of Resident Fees that
      are subject to a Transaction. PayQuad makes no representation to Resident
      that Landlord shall honour each such Receipt as if it were issued by
      Landlord itself.
    </p>
    <li>
      <p><strong>Card Transaction Settlement.</strong></p>
    </li>
    <p>
      If Landlord accepts payment of Resident Fees by Card, and you enter your
      Card information for a Transaction through the Account or on the Site or
      by other means, then the supply of that information shall be your
      authorization to charge the Resident Card for the Resident Fees identified
      in the Account. PayQuad shall have no liability for any late settlements
      of Card or PAD payments.
    </p>
    <li>
      <p><strong>Disputes, Errors and Lost Property.</strong></p>
    </li>
    <p>
      Disputes. In the event of a dispute between PayQuad and either you or
      Landlord or an error in respect of a Transaction, you are required to
      inform PayQuad of the dispute within two (2) business days, then (i)
      PayQuad shall provide both you and Landlord with standard reporting
      concerning the specific Transaction; and (ii) PayQuad reserves the right
      (but has no obligation) to reverse the Transaction in question. All
      complaints concerning Landlord's services or Property shall be directed to
      the Landlord.
    </p>
    <p>
      Errors. PayQuad has no liability under the Resident Agreement. You shall
      indemnify and hold PayQuad harmless from and against any and all
      liabilities of PayQuad that arise from or in relation to any Resident
      Agreement.
    </p>
    <p>
      Lost Property. Where Transaction funds are payable to Landlord or to be
      returned to a Resident and, in either case, the recipient thereof cannot
      be found or their account is no longer active for which a PAD
      Authorization has been granted, PayQuad reserves the right to remit the
      Transaction funds to the provincial/territorial authority responsible for
      collecting lost property. Please keep your Account information up to date
      so this does not happen to you.
    </p>
    <li>
      <p><strong>Intellectual Property Rights</strong></p>
    </li>
    <p>
      PayQuad, or its assignee, is the sole owner or exclusive holder of all
      Intellectual Property and Intellectual Property Rights. In consideration
      of your payment of the Services Fees and/or compliance with all other
      terms of this Agreement, PayQuad grants you a non-exclusive,
      non-transferable, non-sublicensable and revocable right and license to use
      the Services during the term of this Agreement. PayQuad reserves all
      rights not expressly granted to you in this Agreement.
    </p>
    <li>
      <p><strong>Service Fees</strong></p>
    </li>
    <p>
      You shall pay Service Fees for use of the Services. Pursuant to
      instructions from Resident, when Resident initiates any Transaction or
      requests a Service for which Service Fees are applicable, the Service Fees
      will be deducted from Designated Account in addition to Resident Fees. The
      Service Fees are incorporated herein by reference and form a part of this
      Agreement. Service Fees collected are not refundable except and to the
      extent required under Applicable Law. PayQuad may change the Service Fees
      at its own discretion as per the amendment provision of this Agreement.
      Where Landlord informs PayQuad that some or all of the fees for PayQuad
      Services are payable by you instead of by the Landlord, then PayQuad has
      the right to debit such amounts under the PAD Authorization. In addition,
      service fees will not be flagged as a cash back unless the Landlord agrees
      to an Agreement which is specified to the residents as such.
    </p>
    <li>
      <p><strong>Indemnification and Limitation of Liability.</strong></p>
    </li>
    <p>
      Indemnification. You shall defend, indemnify and hold harmless PayQuad,
      its directors, officers, employees, agents, assigns, and
      successors-in-interest and Landlord from and against any and all
      third-party liability, damages, losses, claims, demands, actions, causes
      of action and costs (including attorneys' fees and expenses) arising out
      of or resulting from: (i) Resident's performance under this Agreement
      including, without limitation, performance, non-performance, or defect in
      performance, any statement, misstatement, representation or
      misrepresentation made by Resident; (ii) the negligent or willful acts or
      omissions of Resident; or (iii) performance by Resident or breach by
      Resident under a Resident Agreement.
    </p>
    <p>
      PayQuad is not responsible for any nonpayment of Resident Fees,
      non-sufficient funds, charge backs, or any other nonpayment issue as a
      result of non-payment or inability to pay a Resident.
    </p>
    <p>
      The Services are not those of a rent collection agency, but are instead a
      PAD Authorization and Card-based method for the Landlord to conduct its
      normal Resident Fee collection business.
    </p>
    <p>
      Resident is responsible for ensuring all Designated Account Information is
      correct and remains so for the Term.
    </p>
    <p>
      PayQuad reserves the right to reject or refuse the Services to any
      Resident at its sole discretion, provided that PayQuad's election to not
      service a given Resident is not discriminatory and is in accordance with
      Applicable Law.
    </p>
    <p>
      Neither party shall be liable to the other or any third party for any
      liquidated, indirect, consequential, special, punitive, speculative, lost
      profits, exemplary or incidental damages (including damages for loss of
      business profits, business interruption, loss of business information, and
      the like) arising out of this Agreement even if the party at fault has
      been advised of the possibility of such damages. Notwithstanding the
      foregoing, Landlord shall be a third party beneficiary under this
      Agreement and entitled to enforce the rights of PayQuad hereunder against
      Resident in the same manner and to the same extent as PayQuad can, without
      prejudice to the rights of PayQuad.
    </p>
    <p>
      Limitation of liability. The maximum aggregate liability of PayQuad under
      this Agreement shall not exceed the amount of Service Fees paid to it
      hereunder or from Resident during the six (6) months prior to the event
      giving rise to liability.
    </p>
    <li>
      <p><strong>Independent Contractor Relationship.</strong></p>
    </li>
    <p>
      This Agreement is an independent contractor agreement and not that of an
      employer and employee or principal and agent. The parties hereto
      acknowledge and agree that no joint venture is intended or created by this
      Agreement. Each party shall be solely and entirely responsible for its
      acts and for the acts of its employees, servants and subcontractors during
      the performance of this Agreement.
    </p>
    <li>
      <p><strong>Confidentiality.</strong></p>
    </li>
    <p>
      Each party shall keep the Confidential Information of the other party
      confidential.
    </p>
    <li>
      <p><strong>Term and Termination.</strong></p>
    </li>
    <p>
      The term of this Agreement shall be the same as the term of the Resident
      Agreement unless terminated earlier as provided in this Agreement. This
      Agreement may be terminated, at any time, by either party. PayQuad may
      terminate this Agreement at any time and for no cause on notice to
      Resident. In the event of termination, Resident will remain liable to
      PayQuad for any Service Fees, or other liabilities arising hereunder
      notwithstanding any termination.
    </p>
    <li>
      <p><strong>Governing Law; Arbitration.</strong></p>
    </li>
    <p>
      This Agreement shall be governed by and construed in accordance with, the
      laws of the Province of Ontario, excluding its rules on conflicts of law.
      Any dispute, controversy or claim arising out of or relating to this
      contract including any question regarding its existence, interpretation,
      validity, breach or termination or the business relationship created by it
      shall be referred to and finally resolved by arbitration under the
      Canadian Arbitration Association Arbitration Rules. The place of the
      arbitration shall be Toronto, Ontario. The language of arbitration shall
      be English. There shall be a sole arbitrator who shall be a lawyer,
      licensed in a Canadian Province who is a lawyer practising corporate or
      commercial law.
    </p>
    <li>
      <p><strong>General</strong> Assignment.</p>
    </li>
    <p>
      Resident may not assign any of its rights or obligations under this
      Agreement to any third party without the prior consent of PayQuad.
      Notwithstanding any assignment hereof by Resident, Resident shall remain
      liable to PayQuad for the obligations or Resident hereunder. PayQuad may
      assign its rights or obligations hereunder to a third party on notice to
      Resident. PayQuad reserves the right to have a third party perform some or
      all of its obligations under this Agreement.
    </p>
    <p>Force Majeure.</p>
    <p>
      Neither party shall be liable in damages or have the right to terminate
      this Agreement for any delay or default in performing hereunder if such
      delay or default is caused by conditions beyond its control including, but
      not limited to acts of god, government restrictions (including the denial
      or cancellation of any export or other necessary license), wars,
      insurrections and/or any other cause beyond the reasonable control of the
      party whose performance is affected. The affected party shall notify the
      other party within five days of a delay, default or non-performance
      pursuant to a force majeure event.
    </p>
    <p>Entire Agreement.</p>
    <p>
      This Agreement, including all exhibits, schedules and addenda attached
      hereto, constitutes the entire and exclusive agreement between the parties
      with respect to the subject matter of this Agreement. Any agreements,
      promises, negotiations, representations or other terms not set forth or
      referred to in this Agreement are of no force or effect. This Agreement is
      not part of the Resident Agreement.
    </p>
    <p>Amendments</p>
    <p>
      Payquad may amend or modify this agreement from time to time at its
      discretion, by posting a new version hereof to the Site or portal. You
      accept responsibility for reviewing any updates to this agreement on our
      Site or portal and acknowledge you will be deemed to have agreed to the
      terms of amendments and all updates if you continue to use our Services.
    </p>
    <p>Notices.</p>
    <p>
      Notices shall be effective upon receipt if they are received in writing,
      by registered or certified mail, postage prepaid, return receipt requested
      or
    </p>
    <p>
      by overnight courier to the person and at the address set forth below.
      Notwithstanding the foregoing, PayQuad reserves the right to deliver
      notices under this Agreement to Landlord via the Account or to the e-mail
      provided by Landlord on the Application.
    </p>
    <p>Language.</p>
    <p>
      The parties hereto agree that this Agreement is drafted and executed in
      the English language.
      <i
        >Les parties aux présentes s'entendent pour que la présente convention
        soit rédigée en langue anglaise</i
      >.
    </p>
    <p>Miscellaneous.</p>
    <p>
      If any part of this Agreement shall be deemed invalid under any applicable
      law, the remaining parts of this Agreement shall be in full force and
      effect as though any unenforceable part or parts were not written into
      this Agreement. In construing this Agreement, the singular tense shall be
      deemed to include the plural and the male or neuter gender shall mean and
      comprehend all genders, whenever such meaning or interpretation is
      necessary and appropriate. Headings contained in this Agreement are for
      reference purposes only, and shall not affect in any way the meaning or
      interpretation of this Agreement. This Agreement shall be binding upon the
      parties hereto, their legal representatives, successors and assigns, and
      the parties hereto do hereby covenant and agree that they, their legal
      representatives, successors and assigns will execute any and all papers
      and documents that may be required in accordance with this Agreement.
    </p>
    <p>Survival.</p>
    <p>
      The following provisions hereof shall survive termination of this
      Agreement: 3 COLLECTION USE STORAGE AND DISCLOSURE OF PERSONAL
      INFORMATION, 11 Disputes, Errors and Lost Property, 12 Intellectual
      Property Rights, 13 Service Fees, 14 Indemnification and Limitation of
      Liability, 16 Confidentiality, 18 Governing Law and Arbitration, 19
      General, and 21 Glossary, and any other provision as per Applicable Law.
    </p>
    <li>
      <p><strong>Documents</strong></p>
    </li>
    <p>
      In consideration of use of the Service, you agree to: (a) only purchase or
      access documents or services that you legally have right to purchase or
      access, (b) provide true, accurate, current and complete information about
      yourself as prompted by the Service's registration form (such information
      being the "Account").
    </p>
    <p>
      Some Content offered may indicate a time frame for delivery. PayQuad does
      not create the Content nor control the timeliness of the Content. As such
      the sole responsibility for timeliness is the person or party from which
      such Content originates. Further, some content cannot be provided or
      created in the time frame offered due to external factors. As such, any
      timeliness commitment for delivery starts not when ordered, but at the
      time at which information sufficient for the creation of the Content
      becomes available to the person or party from which the Content
      originates.
    </p>
    <p>
      Some jurisdictions (municipal, state, provincial/territorial, and federal)
      may have set limitations on what documents you are allowed to access, even
      if you could get those documents from PayQuad either for free or for a
      cost. You agree to comply with all jurisdictional laws that apply to you,
      and failure to do so is your responsibility alone.
    </p>
    <p>
      You agree that you must evaluate, and bear all risks associated with, the
      use of any Content, including any reliance on the accuracy, completeness,
      timeliness or usefulness of such Content.
    </p>
    <p>
      You understand that PayQuad does not create, control, author, review,
      monitor, manufacture, vet, hold copyright to, or otherwise have any
      control to timeliness (or lack of), accuracy (including product
      descriptions), quality or integrity of the Content sold through this site.
      As such, you agree that PayQuad has no liability to the Content sold on
      this site, and all liability of Content resides with the creator,
      manufacturer, or copyright holder of the Content purchased through our
      Service.
    </p>
    <p>
      Due to the nature of electronic downloads; there is a NO REFUND policy in
      place. Content, documents and Services are sold on an "as is" basis. For
      documents that require additional information to be supplied before
      delivery but after payment (for example, Estoppels), if the additional
      information is not supplied within 60 days the purchase will be considered
      completed and neither refund nor document will be delivered.
    </p>
    <li>
      <p><strong>Glossary</strong></p>
    </li>
    <p>
      Below are the definitions of capitalized terms used in this Agreement:
    </p>
    <p>
      "<strong>Account</strong>" means an account made available to Resident,
      accessible through the Site, through which Landlord can transmit
      instructions or receive information in relation to the Services;
    </p>
    <p>
      "<strong>Acquiring Bank</strong>" means a bank or financial institution
      that processes the payment of Resident Fee by Resident using his/her Card;
    </p>
    <p>
      "<strong>Affiliate</strong>" means, in relation to a Person, another
      Person that directly or indirectly owns or controls, is owned or
      controlled by, or is under common ownership or common control with the
      Person, or a Person's principal partners, shareholders, or owners of some
      other ownership interest;
    </p>
    <p>
      "<strong>Applicable Laws</strong>" means any and all applicable federal,
      provincial/territorial, municipal or Property laws, regulations, bylaws or
      rules;
    </p>
    <p>
      "<strong>Application</strong>" means an electronic or paper application,
      in a form provided by PayQuad, completed by Resident in conjunction with
      requesting the supply of the Services pursuant to this Agreement;
    </p>
    <p>
      "<strong>Application</strong>" means the paper or online application
      completed by Resident when applying for the Services all of which is
      incorporated herein by reference;
    </p>
    <p>
      "<strong>Authorized Representative</strong>" means the individual
      representative identified in the Application has having the authority to
      bind the Landlord and deliver instructions to PayQuad in respect of the
      Services and this Agreement;
    </p>
    <p>
      "<strong>Bank</strong>" means the Canadian financial institution where the
      Designated Account is held;
    </p>
    <p>
      "<strong>Canada's Anti-Spam Law</strong>" means An Act to promote the
      efficiency and adaptability of the Canadian economy by regulating certain
      activities that discourage reliance on electronic means of carrying out
      commercial activities, and to amend the Canadian Radio-television and
      Telecommunications Commission Act, the Competition Act, the Personal
      Information Protection and Electronic Documents Act and the
      Telecommunications Act (S.C. 2010, c. 23);
    </p>
    <p>
      "<strong>Card Transaction</strong>" means a Resident Fee payment by way of
      the Resident using their Card and such Card being processed by the
      Acquiring Bank;
    </p>
    <p>
      "<strong>Card</strong>" means (i) a valid credit or debit card in the form
      issued under license from Visa International, Inc., the Discover Network
      or MasterCard International, Inc. or their respective affiliates; or (ii)
      any other valid credit card accepted by the Acquiring Bank under the Card
      Processing Agreement;
    </p>
    <p>
      "<strong>Confidential Information</strong>" means all proprietary, secret
      or confidential information or data relating to Services including,
      without limitation, Resident lists, Resident or resident agreements and
      all parts thereof, financial or other data in any format, computer access
      codes, instruction and/or procedural manuals, payroll information, human
      resource or personnel information, business strategies and the terms and
      conditions of this Agreement. Information shall not be considered
      Confidential Information to the extent that such information is: (i)
      already known to the receiving party free of any restriction at the time
      it is obtained; (ii) subsequently learned from an independent third party
      free of any restriction and without breach of this Agreement; (iii)
      becomes publicly available through no wrongful act of the receiving party;
      (iv) independently developed by the receiving party without use of or
      reference to any Confidential Information of the other; or (v) required to
      be disclosed by law;
    </p>
    <p>
      "<strong>CPA Rules</strong>" means the rules of the Canadian Payments
      Association, available at<a href="http://www.cdnpay.ca/">www.cdnpay.ca</a>
    </p>
    <p>
      "<strong>Designated Account Information</strong>" means information
      concerning the Designated Account;
    </p>
    <p>
      "<strong>Designated Account</strong>" means a bank account of Resident
      identified in the PAD Authorization from which Resident Fees are to be
      debited;
    </p>
    <p>
      "<strong>Effective Date</strong>" has the meaning provided in the preamble
      of this Agreement;
    </p>
    <p>
      "<strong>Intellectual Property"</strong> means ideas, discoveries (whether
      or not patentable), developments, improvements, modifications,
      enhancements, additions, literary and artistic works and any other works
      of authorship including, but not limited to, computer programs, software,
      firmware, source code, object code, algorithm, subroutine, object module,
      schematic, model, diagram, flow chart, chip masking specification, user
      manual, compilation of information, work in process, technical data,
      design, formula, device, pattern, concept, art, method, process and any
      and all revisions and improvements relating to any of the foregoing (in
      each case whether or not reduced to tangible form), trademarks, trade
      dress, service marks and domain names, relating to Services;
    </p>
    <p>
      "<strong>Intellectual Property Rights</strong>" means all patent,
      copyright, mask work, trademark, trade dress, service mark, goodwill,
      trade secret, Moral Rights, domain name, right of publicity and any other
      intellectual property rights in Services, whether registered or
      unregistered, and all applications therefore and registrations, renewals
      and extensions thereof, under the laws of any state, country, territory or
      other jurisdiction;
    </p>
    <p>
      "<strong>Invoice</strong>" means an electronic document that represents an
      amount owing from a Resident to a Landlord under a Resident Agreement for
      which the Landlord wishes to receive payment by way of the Services;
    </p>
    <p>
      "<strong>Landlord</strong>" means the Person or company that owns or
      manages the property displayed on this website;
    </p>
    <p>
      "<strong>Moral Rights</strong>" means any and all rights of paternity,
      integrity, disclosure and withdrawal relating to Inventions and
      Proprietary Information and the right to object to any modification,
      translation publication or use of the foregoing, and any similar rights
      existing under the judicial or statutory law of any country in the world
      or under any treaty, regardless of whether or not such right is
      denominated or referred to as a "moral" right.
    </p>
    <p>
      "<strong>PAD Authorization</strong>" means a pre-authorized debit
      agreement as defined in the CPA Rules granting each of (i) Landlord and
      (ii) PayQuad the right to initiate debits and credits on the Designated
      Accounts for Resident Fees and other amounts owing under the Resident
      Agreement or this Agreement;
    </p>
    <p>
      "<strong>PAD</strong>" or "<strong>Direct Debit</strong>" means
      pre-authorized payment within the meaning of the CPA Rules;
    </p>
    <p>
      "<strong>Payment Network</strong>" means Visa International, Inc.,
      MasterCard International, Inc., the Discover Network or any other Card
      Issuers that provide Cards accepted by Landlord by agreement with
      Acquiring Bank.
    </p>
    <p>"<strong>PayQuad</strong>" means PayQuad Solutions Inc.;</p>
    <p>
      "<strong>Person</strong>" is to be broadly interpreted and includes an
      individual, a corporation, a partnership, a trust, an unincorporated
      organization, the government of a country or any political subdivision
      thereof, or any agency or department of any such government, and the
      executors, administrators or other legal representatives of an individual
      in such capacity.
    </p>
    <p>
      "<strong>Prohibited Activity</strong>" means the operation of or the
      direct or indirect facilitation of any activity that is contrary to the
      terms of this Agreement or is illegal under any federal,
      provincial/territorial, municipal or Property law, regulation or bylaw
      including but not limited to any adult or adult-related services,
      including escort services, adult massage, or other adult-entertainment
      services; adult performers or adult webcam services; lottery tickets,
      casino gaming chips, off-track betting, memberships on gambling-related
      internet sites and wagers at races; bill payment services; buyers or
      discount clubs; cigarettes, tobacco or e-cigarettes; credit counseling or
      repair agencies; credit protection or identity theft protection services;
      digital goods including digital currency; direct marketing or subscription
      offers; inbound or outbound telemarketing businesses including lead
      generation businesses; infomercial sales; internet, mail or telephone
      order pharmacies or pharmacy referral services; items that encourage,
      promote, facilitate or instruct others to engage in illegal activity;
    </p>
    <p>
      "<strong>Property</strong>" means a real estate property located in the
      Territory, identified as such in the Application, that is rented or used
      by the Resident or for which Resident has the lawful right to: (i) enter
      into this Agreement; (ii) pay Rent or other Resident Fees; and (iii) enter
      into, modify and terminate Resident Agreements;
    </p>
    <p>
      "<strong>Receipt</strong>" means an electronic receipt of Landlord for
      payment of Resident Fees by Resident under a Resident Agreement;
    </p>
    <p>
      "<strong>Resident Agreement</strong>" or "<strong>Lease</strong>" means
      (i) an enforceable written agreement between Landlord and Resident
      pursuant to which: (A) Resident
    </p>
    <p>
      agrees to lease or rent a Unit from the Landlord or Landlord agrees to
      maintain the Unit in consideration of Resident Fees; and (B) Resident
      grants Landlord a PAD Authorization or (ii) this Agreement;
    </p>
    <p>
      "<strong>Resident Data</strong>" means non-public information concerning a
      Resident provided by Landlord or Resident to PayQuad;
    </p>
    <p>
      "<strong>Resident Fees</strong>" means amounts payable by Resident to
      Landlord under the Resident Agreement in respect of a Unit which may
      include, but might not necessarily be limited to rent, condominium
      association fees or other payables thereunder, such as they may be from
      time to time;
    </p>
    <p>
      "<strong>Resident</strong>" means a Person (tenant, resident, owner) that
      is party to a Resident Agreement in respect of which Landlord wishes to
      use the Services. This includes guest users such as Realtors, Agents or
      other persons who may need to access an Account;
    </p>
    <p>
      "<strong>Services</strong>" " means the service of PayQuad acting as an
      agent of a Landlord for the purpose of, by electronic or other means: (i)
      informing Residents of the Landlord concerning how Transactions may be
      completed for the benefit of the Landlord; (ii) serving as an agent of the
      Landlord for the purpose of (A) causing Landlord to enter into PAD
      Authorizations with Residents, (B) initiating debits on Resident financial
      institution accounts on the basis of the applicable PAD Authorization; and
      (C) causing PayQuad's processor to settle Resident Fees to the PayQuad
      Designated Account; and (iii) serving as an agent of Landlord to deliver
      Invoices and Receipts to Residents and (D) other portal functions which
      may include maintenance requests, messaging, documents, documents
      purchasing, tenant insurance, bookings, applications and leasing and may
      be subject to change;
    </p>
    <p>
      "<strong>Service Fees</strong>" means those amounts for which residents
      are liable to pay hereunder in consideration of the Services, as set out
      in the Application, in the Account or on the Site, such as they may be
      from time to time;
    </p>
    <p>
      "<strong>Site</strong>" means
      <a href="http://www.payquad.com/">www.payquad.com</a>;
    </p>
    <p>
      "<strong>Term</strong>" has the meaning provided in Section 17 of this
      Agreement;
    </p>
    <p>
      "<strong>Territory</strong>" means any province or territory within
      Canada;
    </p>
    <p>
      "<strong>Transaction</strong>" means an actual or attempted payment
      transaction by way of the Services; and
    </p>
    <p>
      "<strong>Unit</strong>" means one or more parts of a Property that are the
      subject of a Resident Agreement.
    </p>
  </ol>
  <p><strong>PRE-AUTHORIZED PAYMENT ("PAD") AUTHORIZATION</strong></p>
  <p>
    <strong
      >(RESIDENT PERMISSION TO LANDLORD AND PAYQUAD SOLUTIONS INC.)</strong
    >
  </p>
  <p>
    THIS PAD AUTHORIZATION IS AN AGREEMENT BETWEEN THE RESIDENT IDENTIFIED IN
    THE APPLICATION, THE LANDLORD IDENTIFIED ON THIS SITE AND PAYQUAD SOLUTIONS
    INC. THE APPLICATION IS AN APPLICATION FOR RESIDENT TO ENTER INTO AN
    AGREEMENT WITH LANDLORD AND A SEPARATE AGREEMENT WITH PAYQUAD SOLUTIONS INC.
    (EACH SUCH AGREEMENT BEING A "<strong>RESIDENT AGREEMENT</strong>" OR
    "<strong>LEASE</strong>") PURSUANT TO WHICH CERTAIN FEES ARE PAYABLE
    ("<strong>RESIDENT FEES</strong>" OR "<strong>RENT</strong>"). IN THIS PAD
    AUTHORIZATION, THE TERM "<strong>Landlord</strong>" SHALL REFER TO EITHER
    LANDLORD AS DEFINED IN THE RESIDENT AGREEMENT OR PAYQUAD SOLUTIONS INC.
  </p>
  <ol>
    <li>
      <p>
        Landlord and Resident wish to provide for the right of Landlord to
        initiate debits of Resident Fees from the Designated Account and credit
        the Designated Account for amounts owing to Resident hereunder. This
        pre-authorized payment ("<strong>PAD</strong>") authorization (the
        "<strong>PAD Authorization</strong>") sets out the specific terms of
        such Landlord rights. The PAD Authorization takes effect as of the
        Effective Date and terminates in accordance with the terms hereof, but
        no later than the date on which Resident has no outstanding obligations
        to Landlord.
      </p>
    </li>
    <li>
      <p>
        The following information, supplied to Landlord in the Application or by
        sending a voided check to Landlord by electronic or other means shall be
        referred to as the ("<strong>Designated Account Information</strong>"):
        Name on Account, Name of Bank, Bank Branch Address, Account Number at
        Bank and Bank Transit Number.
      </p>
    </li>
    <li>
      <p>
        The financial institution identified in the Designated Bank Information
        shall be referred to herein as the "<strong>Bank</strong>" and the bank
        account shall be referred to as the "<strong>Designated Account</strong
        >". Resident hereby permits Landlord to debit the Designated Account
        under the Resident Agreement all Resident Fee payments payable to
        Landlord from Resident and also credit the Designated Account for
        amounts owing by Landlord to Resident pursuant to the Resident
        Agreement. In respect of payment of any amounts owing all regular
        recurring payments will be debited each month on the date prescribed by
        Resident, in the amounts set out in the Resident Agreement.
      </p>
    </li>
    <li>
      <p>
        <strong
          >Resident hereby waives its right to receive pre-notification of the
          amount of the PAD and agree that Resident does not require advance
          notice of the amount of PADs before the debit or credit is
          processed.</strong
        >
      </p>
    </li>
    <li>
      <p>
        This PAD Authorization may be cancelled provided notice is received at
        least thirty (30) days before the next scheduled PAD. If the Designated
        Account Information changes, please contact Landlord immediately through
        the contact information set out above. Landlord has certain recourse
        rights if any debit does not comply with these terms. To obtain more
        information on Landlord recourse rights, contact Bank or visit
        <a href="http://www.cdnpay.ca/">www.cdnpay.ca</a> (the "CPA Rules").
      </p>
    </li>
    <li>
      <p>
        In particular, Resident further agrees that if any payment is
        dishonoured by the Bank for any reason, then Landlord shall be entitled
        to issue another debit or credit in substitution for the dishonoured
        debit or credit. Resident acknowledges that this PAD Authorization is
        provided for the benefit of Landlord and the Bank and is provided in
        consideration of the Bank agreeing to process debits against the
        Designated Account in accordance with the CPA Rules.
      </p>
    </li>
    <li>
      <p>
        Resident shall be charged a fee for each credit and debit that cannot be
        processed and all subsequent funding may be suspended until Resident
        either: (i) notifies Resident that credits and debits can be processed
        or (ii) a new PAD is accepted by Resident.
      </p>
    </li>
    <li>
      <p>
        Bank's treatment of each debit shall be the same as if Resident had
        issued its cheque authorizing the Bank to pay as indicated and to debit
        the amount specified to Designated Account. Resident confirms that this
        means, in part, that the Bank is not required to verify that a PAD debit
        has been issued in accordance with Resident's instructions or that some
        pre-condition to payment has been met or satisfied.
      </p>
    </li>
    <li>
      <p>
        This PAD Authorization may be cancelled at any time by written notice by
        Resident to Landlord which notice shall be effective five (5) business
        days after receipt. To obtain a sample cancellation form, or for more
        information on the right to cancel this PAD Authorization, Resident
        understands that Resident may contact Bank or visit
        <a href="http://www.cdnpay.ca/">www.cdnpay.ca</a>. This PAD
        Authorization applies only to the method of payment and Resident agrees
        that revocation of this PAD Authorization does not terminate or
        otherwise having any bearing on the Resident Agreement. Delivery of this
        PAD Authorization, to Landlord by electronic acceptance hereof or by
        other means, constitutes delivery by Resident to Bank. Resident confirms
        that the debits authorized hereunder are for purposes consistent with
        the Resident Agreement. Resident hereby waives the right to receive any
        notice, written or otherwise, from Landlord of the amount to be debited
        and the date(s) on which such debits are to be processed, as well as
        notice of any and all future changes to the amounts or payment dates.
      </p>
    </li>
    <li>
      <p>
        Resident consents to the disclosure of any relevant information
        contained in the Resident Agreement and this PAD Authorization to the
        financial institution for purposes of the PAD. Landlord may assign this
        PAD Authorization, whether directly or indirectly, by operation of law,
        change of control, or otherwise, by providing at least 10 days prior
        notice to Resident. All capitalized terms used in this PAD Authorization
        and not otherwise defined shall have the meaning set out in the Resident
        Agreement.
      </p>
    </li>
    <li>
      <p>
        Resident understands that Resident may contact Landlord at the address
        set out above or that set out in the Resident Agreement to make
        inquiries, obtain information or seek any recourse rights.
      </p>
    </li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close color="warn">
    {{ "Close" | translate }}
  </button>
</mat-dialog-actions>
