import {ScheduledMessage} from '../resources/scheduled-message';

export interface MessagesState {
  scheduled: Array<ScheduledMessage>;
  isDashboardEnabled: boolean;
}

export const initialMessagesState: MessagesState = {
  scheduled: [],
  isDashboardEnabled: false
};
