<div class="container" style="margin-bottom:30px">
  <div class="row">
    <div class="col-xs-12 align-center">
      <div class="companyicon">
        <img ng-hide="branding.hideCompanyLogo" src="{{branding.logo_file_url}}"/>
      </div>
    </div>
  </div>
</div>

<div class="container-1024" style="margin-top:-20px">
  <div class="row" align="center" style="margin-bottom: 10px">
    <div style="
        height: 1px;
        margin-bottom: 30px;
  overflow: visible;
  border: none;
  background: none;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(150, 150, 150, 0)), color-stop(15%, rgba(150, 150, 150, 0)), color-stop(50%, rgba(150, 150, 150, 0.65)), color-stop(85%, rgba(150, 150, 150, 0)), color-stop(100%, rgba(150, 150, 150, 0)));
  background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -moz-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -ms-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -o-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#00000000', GradientType=1);"></div>
    <div
      *ngIf="branding.welcome_message != '' && branding.welcome_message != null && branding.welcome_message !== undefined"
      [innerHTML]="branding.welcome_message" style="margin-bottom: 30px"></div>
  </div>

  <div class="row" style="padding: 0 15px">

    <div class="loginBox">
      <h1 class="text-center">Two Factor Authentication Required</h1>

      <div class="row">
        <div class="form-item">
          <p class="bg-danger" id="error-messages-container" *ngFor="let error of errors | keyvalue">
            {{ error.value[0] }}
          </p>
        </div>
      </div>

      <div class="row text-center" style="margin-bottom: 15px">
        Enter a code from your two-factor authenticator app<br>
        Don't have your device? Contact <strong><a href="mailto:support@payquad.com" class="info">support&#64;payquad.com</a></strong>
      </div>

      <form (submit)="submit()" class="form-horizontal" role="form" id="signin-form">
        <div class="col-xs-12 form-item input">
          <input [(ngModel)]="code"
                 class="form-control "
                 placeholder="XXXXXX"
                 autocomplete="one-time-code"
                 minlength="6" maxlength="6" numbers-only
                 name="email"
                 type="text">
        </div>
        <div class="row actions">
          <div class="col-xs-12 col-md-3">

            <div class="button">
              <button (click)="logout()" type="button" class="btn btn-danger">Log out</button>
            </div>

          </div>
          <div class="col-xs-12 col-md-6"></div>

          <div class="col-xs-12 col-md-3">

            <div class="button ">
              <button type="submit" [disabled]="isLoading" class="btn btn-success">Submit</button>
            </div>

          </div>

        </div>
      </form>

    </div>

  </div>

</div>

