<mat-toolbar [ngClass]="{'main-toolbar-mobile': (layoutService.isMobile|async)}">
  <mat-toolbar-row>
    <div class="logo-container"
         [ngClass]="{'logo-container-closed': !(layoutService.sidenavOpened|async)}"
         *ngIf="!(layoutService.isMobile|async)"
    >
      <a title="Branding" (click)="goToDashboard()">
        <img src="{{branding.nav_logo_file_url}}" height="47"/>
      </a>
      <button mat-icon-button
              class="sidebar-control"
              [ngClass]="{'sidebar-control-opened': (layoutService.sidenavOpened|async)}"
              (click)="layoutService.toggleSidenav()"
      >
        <mat-icon svgIcon="pq:arrow-forward"></mat-icon>
      </button>
    </div>
    <div class="burger-logo"
         *ngIf="(layoutService.isMobile|async)"
    >
      <button mat-icon-button
              (click)="layoutService.toggleSidenav()"
      >
        <mat-icon svgIcon="pq:menu-burger"></mat-icon>
      </button>
      <a href="/#/n/dashboard">
        <img src="{{branding.nav_logo_file_url}}" height="28"/>
      </a>
    </div>
    <div class="title-container" *ngIf="!(layoutService.isMobile|async)">
      <span>{{ title | async}}</span>
    </div>
    <div class="profile-container">
      <a href="{{ this.authUser.has_angular_messaging_access ? '/#/n/messages/inbox/tenants' : '/#/messages' }}">
        <button mat-icon-button>
          <mat-icon class="mail-icon" svgIcon="pq:mail"></mat-icon>
          <span class="pq-badge danger"
                *ngIf="(messagesService.newMessageCount|async); let c"
          >{{c}}</span>
        </button>
      </a>
      <div class="profile__language-button" [matMenuTriggerFor]="toolbarLanguage">
        <mat-icon svgIcon="pq:language"></mat-icon>
        <span>{{localeButtonString | titlecase}}</span>
      </div>

      <div mat-ripple
           class="profile-button"
           [matMenuTriggerFor]="profileMenu"
           *ngIf="!(layoutService.isMobile|async)"
      >
        <img *ngIf="isCordova" src="assets/icons/Profile Avatar.svg" />
        <img *ngIf="!isCordova" src="dist/assets/icons/Profile Avatar.svg" />

        <span>{{authUser.first_name}}</span>
      </div>
      <div class="profile-button-mobile"
           matRipple
           [matMenuTriggerFor]="profileMenu"
           *ngIf="(layoutService.isMobile|async)"
      >
        <img *ngIf="isCordova" src="assets/icons/Profile Avatar.svg" />
        <img *ngIf="!isCordova" src="dist/assets/icons/Profile Avatar.svg" />

      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #toolbarLanguage="matMenu" class="pq-menu pq-menu-right main-navigation-menu" xPosition="before">
  <div class="triangle"></div>
  <button mat-menu-item (click)="setLocalization('en')">
    <div class="mat-icon-container"><mat-icon class="icon" svgIcon="pq:language"></mat-icon></div>
    <span>{{'English' | translate}}</span>
    <mat-icon svgIcon="pq:checkmark_circle" class="checkmark_circle" *ngIf="localeButtonString === 'en'"></mat-icon>
  </button>
  <button mat-menu-item (click)="setLocalization('fr')">
    <div class="mat-icon-container"><mat-icon svgIcon="pq:language"></mat-icon></div>
    <span>{{'French' | translate}}</span>
    <mat-icon svgIcon="pq:checkmark_circle" class="checkmark_circle" *ngIf="localeButtonString === 'fr'"></mat-icon>
  </button>
</mat-menu>

<div class="mobile-bottom-navigation"
     [ngClass]="{'mobile-bottom-navigation__hide': (layoutService.sidenavOpened|async)}"
     *ngIf="(layoutService.isMobile|async)"
>
  <mat-nav-list>
    <a mat-list-item href="/#/tenants/manage" *ngIf="authUser.user_permissions.residents">
      <mat-icon mat-list-item-icon svgIcon="pq:home"></mat-icon>
      <span mat-line>{{'Residents' | translate}}</span>
    </a>
    <a mat-list-item href="/#/payments" *ngIf="authUser.user_permissions.payments">
      <mat-icon mat-list-item-icon svgIcon="pq:wallet"></mat-icon>
      <span mat-line>{{'Payments' | translate}}</span>
    </a>
    <a mat-list-item href="/#/maintenance-requests" *ngIf="authUser.user_permissions['maintenance-requests']">
      <mat-icon mat-list-item-icon svgIcon="pq:wrench"></mat-icon>
      <span mat-line>{{'Maintenance' | translate}}</span>
    </a>
    <a mat-list-item href="/#/reports" *ngIf="authUser.user_permissions.reports">
      <mat-icon mat-list-item-icon svgIcon="pq:copy"></mat-icon>
      <span mat-line>{{'Reports' | translate}}</span>
    </a>
  </mat-nav-list>
</div>
<mat-menu #profileMenu="matMenu" class="pq-menu pq-menu-right" xPosition="before">
  <div class="triangle"></div>
  <app-sidenav-profile [authUser]="authUser"></app-sidenav-profile>
</mat-menu>
