import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface PadAgreementResponse {
  result?: {
    body: string;
    revision_date: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private http: HttpClient) {}


  getPadAgreement(): Observable<PadAgreementResponse> {
    return this.http.get<PadAgreementResponse>(`${environment.domain}/api/v1/pad-agreement`);
  }
}
