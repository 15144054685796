import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes, UrlHandlingStrategy} from '@angular/router';
import {AuthGuard} from './shared/guards/auth-guard';
import {ManagerGuard} from './shared/guards/manager-guard';
import {PayquadRouteStrategy} from './shared/classes/payquad-route-strategy';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {LoginComponent} from "./authentication/login/login.component";
import {Auth2faComponent} from "./authentication/auth2fa/auth2fa.component";
import { PermissionGuard } from './shared/guards/permission-guard';
import { UserPermissions } from './shared/interfaces/user';
import {SetUpComponent} from "./authentication/2fa/set-up/set-up.component";
import {TwoFactorAuthGuard} from "./shared/guards/two-factor-auth-guard";
import {OfflineGuard} from "./shared/guards/offline-guard";
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'n/dashboard',
        canActivate: [AuthGuard, ManagerGuard, TwoFactorAuthGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'n/companies',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule)
      },
      {
        path: 'n/payments',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'n/residents',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/residents/residents.module').then(m => m.ResidentsModule)
      },
      {
        path: 'n/parcels',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/parcels/parcels.module').then(m => m.ParcelsModule)
      },
      {
        path: 'n/documents',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'n/inspections',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/inspections/inspections.module').then(m => m.InspectionsModule)
      },
      {
        path: 'n/surveys',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/surveys/surveys.module').then(m => m.SurveysModule)
      },
      {
        path: 'n/maintenance',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path: 'n/maintenance-request',
        canActivate: [OfflineGuard],
        loadChildren: () => import('./modules/maintenance-request/maintenance-request.module').then(m => m.MaintenanceRequestModule)
      },
      {
        path: 'n/maintenance-requests',
        canActivate: [OfflineGuard],
        loadChildren: () => import('./modules/maintenance-request/maintenance-request.module').then(m => m.MaintenanceRequestModule)
      },
      {
        path: 'n/lease-applications',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/lease-applications/lease-applications.module').then(m => m.LeaseApplicationsModule)
      },
      {
        path: 'n/messages',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: 'n/managers',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/managers/managers.module').then(m => m.ManagersModule)
      },
      {
        path: 'n/revenues',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/revenues/revenues.module').then(m => m.RevenuesModule)
      },
      {
        path: 'n/reports',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'n/properties',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/properties/properties.module').then(m => m.PropertiesModule)
      },
      {
        path: 'n/mobile-apps',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/mobile-apps/mobile-apps.module').then(m => m.MobileAppsModule)
      },
      {
        path: 'n/leases',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/leases/leases.module').then(m => m.LeasesModule)
      },
      {
        path: 'n/social-event',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/social-events/social-events.module').then(m => m.SocialEventsModule)
      },
      {
        path: 'n/showings',
        canActivate: [OfflineGuard],
        loadChildren: () => import('./modules/showings/showings.module').then(m => m.ShowingsModule)
      },
      {
        path: 'n/call-tracking',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard],
        loadChildren: () => import('./modules/call-tracking/call-tracking.module').then(m => m.CallTrackingModule)
      },
      {
        path: 'n/offline',
        canActivate: [AuthGuard, ManagerGuard],
        loadChildren: ()  => import('./modules/offline/offline.module').then(m => m.OfflineModule)
      },
      {
        path: 'n/templates',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule)
      },
      {
        path: 'n/prospects',
        canActivate: [AuthGuard, ManagerGuard, OfflineGuard, PermissionGuard(UserPermissions.ProspectEvents)],
        loadChildren: () => import('./modules/prospects/prospects.module').then(m => m.ProspectsModule)
      },
      {
        path: 'n/welcome-message',
        canActivate: [AuthGuard, OfflineGuard],
        loadChildren: () => import('./modules/welcome-message/welcome-message.module').then(m => m.WelcomeMessageModule)
      },
      {
        path: 'n/login',
        component: LoginComponent
      },
      {
        path: 'n/password/reset',
        component: ForgotPasswordComponent
      },
      {
        path: 'n/password/reset/:token',
        component: ResetPasswordComponent
      },
      {
        path: 'n/2fa',
        component: Auth2faComponent
      },
      {
        path: 'n/2fa/setup',
        canActivate: [AuthGuard, OfflineGuard],
        component: SetUpComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    enableTracing: false
})],
  exports: [RouterModule],
  providers: [
    {provide: UrlHandlingStrategy, useClass: PayquadRouteStrategy}
  ]
})
export class AppRoutingModule {

  constructor(private router: Router) {
  }

}
