import { createReducer, on, Action } from '@ngrx/store';
import { CompaniesState, initialCompaniesState } from './companies.state';
import { companiesLoaded, companyChartsLoaded } from './companies.actions';

const _reducer = createReducer(
  initialCompaniesState,
  on(companiesLoaded, (state, { companies }) => {
    return { ...state, companies };
  }),
  on(companyChartsLoaded, (state, { data }) => ({ ...state, companyChart: data }))
);

export function companiesReducer(state: CompaniesState | undefined, action: Action) {
  return _reducer(state, action);
}
