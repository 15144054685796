import { Injectable } from '@angular/core';
import { JWTServiceInterface } from './JWT-service.interface';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class JWTNativeService implements JWTServiceInterface {

  isCordova = Boolean(window.Capacitor.isNativePlatform());

  constructor() {
  }

  async getToken() {
    if (!this.isCordova) {
      return;
    }

    const refreshData = async () => {
      let savedJWT = await this.getJWTFromPreferences();
      return Promise.resolve(savedJWT);
    };

    return await refreshData();
  }

  async getJWTFromPreferences() {
    if (!this.isCordova) {
      return;
    }
    try {
      const { value } = await Preferences.get({ key: 'token' });
      console.log('getJWTFromPreferences 2', value);
      if (value) {
        return value;
      }
      // throw new Error('Token not found');
    } catch (error) {
      console.log('getJWTFromPreferences error', error);
      throw error;
    }
  }

  async setToken(token: string | null = null, type = 'Bearer', expired = 0) {
    console.log('set token', token)
    if (!this.isCordova) {
      // @ts-ignore
      window.cachedToken = '';
      return;
    }
    if (!token) {
      await this.removeToken();
    } else {
      await this.setPreferencesToken(token);
    }
  }

  async removeToken() {
    try {
      await Preferences.remove({ key: 'token' });
      console.log('Token removed successfully');
    } catch (error) {
      console.error('ERROR DELETING TOKEN FROM PREFERENCES', error);
    }
  }

  async setPreferencesToken(token: string) {
    // @ts-ignore
    window.cachedToken = token;
    try {
      await Preferences.set({ key: 'token', value: token });
      console.log('set capacitor token');
    } catch (error) {
      console.log('ERROR SETTING TOKEN IN PREFERENCES', error);
    }
  }
}
