<footer id="app-footer" class="container-fluid app-footer" ng-style="getFooterStyle()">
  <div class="container">
    <div class="row payquad">
      <div class="col-xs-12 col-md-4 align-center info">

        <p class="align-left">
          <b>{{branding.name}}</b>
          <br/>
          {{branding.address}}
          <br>
          <span *ngIf="branding.phone_number && branding.phone_number.length > 2">
                        	<span class="glyphicon glyphicon-phone-alt"></span> {{branding.phone_number}}<br>
                        </span>
          <span *ngIf="branding.email && branding.email.length > 2">
	                        <a href="mailto:{{branding.email}}"><span
                            class="glyphicon glyphicon-envelope"></span>  {{branding.email}}</a><br>
                        </span>
          <span *ngIf="branding.website" class="cordovaHide">
	                        <a href="{{branding.website}}"><span
                            class="glyphicon glyphicon-globe"></span>  {{branding.website}}</a>
                        </span>
        </p>
      </div>
      <div class="col-xs-12 col-md-4 brand   align-center" style="text-align:center;padding-top: 0; margin-top: 0;">
        <span style="font-size:10px;">Powered by</span><br>
        <img src="/assets/svg/footer-payquad-logo.svg"><br>
        <img src="/assets/images/geotrust.png" style="margin-top: 10px;"/>
      </div>
      <div class="col-xs-12 col-md-4 align-center social" style="padding-top:25px;">
            <span *ngIf="branding.facebook && branding.facebook.length > 1" class="cordovaHide">
                <a href="{{branding.facebook}}" title="Facebook">
                  <img src="/assets/svg/icon-social-facebook.svg">
                </a>
            </span>
            <span *ngIf="branding.facebook && branding.twitter.length > 1" class="cordovaHide">
                <a href="{{branding.twitter}}" title="Twitter">
                  <img src="/assets/svg/icon-social-twitter.svg">
                </a>
            </span>
        <br/>
        <button
          [title]="'Terms & Conditions' | translate "
          class="info terms-button" 
          (click)="openTerms()">
          {{ 'Terms & Conditions' | translate }}
        </button>
        <span> | </span>
        <button
          [title]="'Privacy Policy' | translate "
          class="info terms-button" 
          style="padding-right: 10px;"
          (click)="openPrivacyPolicy()">
          {{ "Privacy Policy" | translate }}
        </button>
        <div style="margin:0; padding:0; font-size:11px">
          <a href="mailto:support@payquad.com" class="info">support&#64;payquad.com</a>
          <span> | </span>
          <a href="tel:18883859037" style=" padding-right: 10px;"
             class="info">1 888 385 9037</a>
        </div>
      </div>
    </div>
  </div>
</footer>
