import {Injectable, NgZone} from '@angular/core';
import {Network} from "@capacitor/network";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";
import {tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Preferences} from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  isOffline = false;

  isCaching = false;

  offline_cache_created_at: string = '';

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {
    Network.getStatus().then(status => {
        this.zone.run(() => {
          this.isOffline = !status.connected;
        });
    });

    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);

      this.zone.run(() => {
        this.isOffline = !status.connected;
      });
    });
    Preferences.get({key: 'offline_cache_created_at'}).then((res) => {
      this.offline_cache_created_at = res.value;
    });
  }

  getCache(): Observable<any> {
    this.isCaching = true;
    return this.http.get<Observable<any>>(`${environment.domain}/api/v1/mobile-app/offline/cache`).pipe(
      tap(() => {
        this.isCaching = false;
        this.toastr.success('Cache created successfully');
        Preferences.set({key: 'offline_cache_created_at', value: new Date().toISOString()});
        this.offline_cache_created_at = new Date().toISOString();
      })
    );
  }

}
