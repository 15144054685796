import {createAction, props} from '@ngrx/store';
import {ScheduledMessage} from '../resources/scheduled-message';

export enum MessagesActions {
  LOAD_SCHEDULED = '[Messages] Load scheduled messages',
  SET_SCHEDULED = '[Messages] Set scheduled messages',
  LOAD_IS_DASHBOARD = '[Messages] Load is dashboard messages enabled',
  SET_IS_DASHBOARD = '[Messages] Set is dashboard messages enabled',
  CANCEL_SCHEDULED = '[Messages] Delete scheduled message',
  REMOVE_SCHEDULED = '[Messages] Delete scheduled message'
}

export const loadScheduled = createAction(MessagesActions.LOAD_SCHEDULED);
export const setScheduled = createAction(MessagesActions.SET_SCHEDULED, props<{messages: ScheduledMessage[]}>());
export const loadIsDashboard = createAction(MessagesActions.LOAD_IS_DASHBOARD);
export const setIsDashboard = createAction(MessagesActions.SET_IS_DASHBOARD, props<{isEnabled: boolean}>());
export const cancelScheduled = createAction(MessagesActions.CANCEL_SCHEDULED, props<{id: number}>());
export const removeScheduled = createAction(MessagesActions.REMOVE_SCHEDULED, props<{id: number}>());
