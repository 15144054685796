import { Action, createReducer, on } from '@ngrx/store';
import {initialLeaseApplicationsState, LeaseApplicationsState} from './lease-applications.state';
import {setConversionsChart, setNewChart, setSourcesChart, setTimeLapsChart} from './lease-applications.actions';

export function leaseApplicationsReducer(state: LeaseApplicationsState | undefined, action: Action) {
  return createReducer(
    initialLeaseApplicationsState,
    on(setNewChart, (currentState: LeaseApplicationsState, {data}) => ({...currentState, newChartData: data})),
    on(setSourcesChart, (currentState: LeaseApplicationsState, {data}) => ({...currentState, sourcesChartData: data})),
    on(setTimeLapsChart, (currentState: LeaseApplicationsState, {data}) => ({...currentState, timeLapsChartData: data})),
    on(setConversionsChart, (currentState: LeaseApplicationsState, {data}) => ({...currentState, conversionChartData: data}))
  )(state, action);
}
