import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import {environment} from "../environments/environment";
import {Network} from "@capacitor/network";
import {switchMap} from "rxjs/operators";
import {App} from "@capacitor/app";
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  pqAppId = 'com.payquad.manager';
  appVersion = environment.appVersion;
  // platform: 'ios' | 'android' | 'browser' = window.cordova ? window.cordova.platformId : 'browser';
  platform: 'ios' | 'android' | 'browser' = 'android';
  isValid: boolean;
  link: '';

  constructor(private http: HttpClient) {
  }

  public checkVersion(): Observable<any> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (!status.connected) {
          return of({
            success: true,
            message: 'No internet connection',
            code: 200,
            data: {
              valid: true
            }
          });
        }

        // Convert the Promise to an Observable
        return from(App.getInfo()).pipe(
          switchMap(({ id, version }) =>
            this.http.get(`${environment.domain}/api/v1/mobile-apps/check?app-id=${id}&version=${version}&platform=${Capacitor.getPlatform()}`)
          )
        );
      })
    );
  }


  openStoreLink(link: string) {
    window.cordova.InAppBrowser.open(link, '_system');
  }
}
