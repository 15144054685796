import { Property } from "../interfaces/property";
import { PropertyChartData } from "../interfaces/propertyChartData";

export interface PropertiesState {
  properties: Array<Property>;
  propertyChart: PropertyChartData;
}

export const initialPropertiesState: PropertiesState = {
  properties: [],
  propertyChart: {
    approvedTransactions: [],
    paidTransactions: [],
    usersUse: [],
    paymentMethods: [],
    ticks: []
  }
}
