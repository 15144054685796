import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutComponent} from './layout/layout.component';
import {NavbarComponent} from './layout/navbar/navbar.component';
import {FooterComponent} from './layout/footer/footer.component';
import {SharedModule} from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Store, StoreModule} from '@ngrx/store';
import { reducers, metaReducers, appReducer} from './store/app.reducers';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './store/app.effects';
import {ToastrModule} from 'ngx-toastr';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ManagerNavbarComponent} from './layout/navbar/manager-navbar/manager-navbar.component';
import {TenantNavbarComponent} from './layout/navbar/tenant-navbar/tenant-navbar.component';
import {State} from './store/app.state';
import {AppService} from './app.service';
import {forkJoin, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {setBranding, setLocale, setTranslations} from './store/app.actions';
import {TokenInterceptor} from './token-interceptor';
import {JWTService} from './shared/services/JWT.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {SidenavComponent} from './layout/sidenav/sidenav.component';
import {MatListModule} from '@angular/material/list';
import {MatBadgeModule} from '@angular/material/badge';
import {SidenavProfileComponent} from './layout/sidenav-profile/sidenav-profile.component';
import {MatMenuModule} from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './authentication/login/login.component';
import {AppVersionService} from './app-version.service';
import { ClipboardHelper } from './shared/helpers/clipboard-helper';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from 'src/assets/i18n/paginator-i18n';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Auth2faComponent } from './authentication/auth2fa/auth2fa.component';
import {OptInterceptor} from './opt-interceptor';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { QuillModule } from 'ngx-quill';
import {SetUpComponent} from "./authentication/2fa/set-up/set-up.component";
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { MobileHelper } from './shared/helpers/mobile-helper';

function initAppFactory(appService: AppService, appVersionService: AppVersionService, store: Store<State>, dateAdapter: DateAdapter<Date>) {
  return () => forkJoin({
    branding: appService.loadBranding(),
    translations: appService.loadTranslations(),
    versionValid: window.cordova ? appVersionService.checkVersion() : of({versionValid: {success: true}})
  }).pipe(
    tap(response => {
      store.dispatch(setBranding({branding: response.branding.data}));
      store.dispatch(setTranslations({translations: response.translations.translations}));
      store.dispatch(setLocale({locale: response.translations.current_locale}));
      appVersionService.isValid = response.versionValid.success;
    })
  )
    .toPromise();
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeEn);
registerLocaleData(localeFr);

@NgModule({ declarations: [
        AppComponent,
        LayoutComponent,
        NavbarComponent,
        SidenavComponent,
        SidenavProfileComponent,
        FooterComponent,
        ManagerNavbarComponent,
        TenantNavbarComponent,
        LoginComponent,
        Auth2faComponent,
        SetUpComponent,
        // OrderByPipe,
    ],
    exports: [
        QuillModule
        // OrderByPipe
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreModule.forFeature('app', appReducer),
        StoreDevtoolsModule.instrument({ maxAge: 25, connectInZone: true }),
        EffectsModule.forRoot([AppEffects]),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
          compiler: {
            provide: TranslateCompiler,
            useClass: TranslateMessageFormatCompiler, // to use ICU format for plurals
          },
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
        }),
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule,
        MatListModule,
        MatBadgeModule,
        MatRippleModule,
        MatMenuModule,
        FormsModule,
        QuillModule.forRoot()], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initAppFactory,
            deps: [AppService, AppVersionService, Store],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OptInterceptor, multi: true },
        JWTService,
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (translateService: TranslateService) => translateService.currentLang || 'en',
            deps: [TranslateService]
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: LOCALE_ID,
            useFactory: (translateService: TranslateService) => translateService.currentLang || 'en',
            deps: [TranslateService]
        },
        ClipboardHelper,
        MobileHelper,
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi())
    ] 
  })
export class AppModule {
  ngDoBootstrap(app) {
    console.log('ngDoBootstrap', app);
  }
}
