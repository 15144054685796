import { Action, createReducer, on } from "@ngrx/store";
import { initialPropertiesState, PropertiesState } from "./properties.state";
import { propertiesLoaded, propertyChartsLoaded } from './properties.action';

export function propertiesReducer(state: PropertiesState | undefined, action: Action) {
  return createReducer(
    initialPropertiesState,
    on(propertiesLoaded, (currentState: PropertiesState, {properties}) => ({
      ...currentState, properties
    })),
    on (propertyChartsLoaded, (currentState: PropertiesState, {data}) => ({
      ...currentState, propertyChart: data
    }))
  )(state, action);
}
