<div class="wrapper">
    <div class="companyicon">
        <img ng-hide="branding.hideCompanyLogo" [src]="branding.logo_file_url"/>
    </div>
    <mat-card class="form-mat-card">
        <div class="title">{{'Set Up a New Password' | translate}}</div>
        <form class="pq-form" [formGroup]="formGroup">
            <label>{{'Email' | translate}}</label>
            <mat-form-field class="full-width-field">
                <input matInput placeholder="example@domain.com" formControlName="email">
                <mat-error>
                    <span *ngIf="formGroup.controls['email']?.errors?.required">{{'Email is required field' | translate}}</span>
                    <!--                <span *ngIf="formGroup.controls['email']?.errors?.email">{{'Not valid email' | translate}}</span>-->
                    <span *ngIf="formGroup.controls['email']?.errors?.email?.length" [innerHtml]="formGroup.controls['email'].errors?.email"></span>
                </mat-error>
            </mat-form-field>

            <label>{{'Create Password' | translate}}</label>
            <mat-form-field class="full-width-field">
                <input
                    matInput
                    formControlName="password"
                    [type]="(showPassword | async) ? 'text' : 'password'"
                    [placeholder]="'Password, at least 6 characters' | translate"
                />
                <button matSuffix mat-icon-button tabindex="-1" (click)="toggleShowPassword()">
                    <mat-icon svgIcon="pq:eye"></mat-icon>
                </button>
                <mat-error>
                    <span *ngIf="formGroup.controls['password']?.errors?.required">{{'Password is required field' | translate}}</span>
                    <span *ngIf="formGroup.controls['password']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
                </mat-error>
            </mat-form-field>

            <label>{{'Confirm Password' | translate}}</label>
            <mat-form-field class="full-width-field">
                <input
                    matInput
                    formControlName="password_confirmation"
                    [type]="(showPasswordConfirmation | async) ? 'text' : 'password'"
                    [placeholder]="'Repeat password' | translate"
                />
                <button matSuffix mat-icon-button tabindex="-1" (click)="toggleShowPasswordConfirmation()">
                    <mat-icon svgIcon="pq:eye"></mat-icon>
                </button>
                <mat-error>
                    <span *ngIf="formGroup.controls['password_confirmation']?.errors?.required">{{'Password is required field' | translate}}</span>
                    <span *ngIf="formGroup.controls['password_confirmation']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
                    <span *ngIf="formGroup.controls['password_confirmation']?.errors?.notSame">{{'Password mismatch' | translate}}</span>
                </mat-error>
            </mat-form-field>

            <div class="one-row one-row-stop-break one-row-small-gap body4">
                <mat-checkbox color="primary" formControlName="terms">
                    <span class="body4" style="line-height: 19px">
                        {{'I have read and agree to the ' | translate}}
                        <a tabindex="1" class="accent-primary-1" (click)="$event.stopPropagation(); openTerms()">
                            {{ "Terms & Conditions" | translate }}
                        </a>
                        {{'and' | translate}}
                        <a tabindex="1" class="accent-primary-1" (click)="$event.stopPropagation(); openPrivacyPolicy()">
                            {{ "Privacy Policy" | translate }}
                        </a>
                    </span>
                </mat-checkbox>
            </div>
            
            <mat-error *ngIf="formGroup.controls['terms'].touched && formGroup.controls['terms']?.errors?.required">
                <span class="body4">
                    {{'Terms&nbsp;of&nbsp;Service' | translate}}
                    {{'and' | translate}}
                    {{'Privacy&nbsp;Policy' | translate}}
                    {{'is required' | translate}}
                </span>
            </mat-error>
            <button
                mat-flat-button
                color="primary"
                class="full-width-field send-button"
                (click)="send()"
                [disabled]="disableButton|async"
            >{{'Reset Password' | translate}}</button>
        </form>
    </mat-card>
</div>
