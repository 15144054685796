import { createAction, props } from "@ngrx/store";
import { Property } from "../interfaces/property";
import { PropertyChartData } from './../interfaces/propertyChartData';

export enum PropertiesActions {
  LOAD_PROPERTIES = "[Properties] Load Properties",
  PROPERTIES_LOADED = "[Properties] Properties Loaded",
  LOAD_PROPERTY_CHARTS = "[Properties] Load property charts",
  PROPERTY_CHARTS_LOADED = "[Properties] set loaded charts for property"
}

export const loadProperties = createAction(
  PropertiesActions.LOAD_PROPERTIES
);

export const propertiesLoaded = createAction(
  PropertiesActions.PROPERTIES_LOADED,
  props<{ properties: Property[] }>()
);

export const loadPropertyCharts = createAction(
  PropertiesActions.LOAD_PROPERTY_CHARTS,
  props<{ propertyId: number }>()
);

export const propertyChartsLoaded = createAction(
  PropertiesActions.PROPERTY_CHARTS_LOADED,
  props<{ data: PropertyChartData }>()
);
