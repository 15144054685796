import {Component, OnDestroy, OnInit} from '@angular/core';
import {Branding} from '../../shared/interfaces/branding';
import {Store} from '@ngrx/store';
import {State} from '../../store/app.state';
import {selectAppBranding, selectAppLocale, selectAppUser} from '../../store/app.selectors';
import {UnsubscribableComponent} from '../../shared/classes/abstract/unsubscribable-component';
import {loadTranslations, setLocale} from '../../store/app.actions';
import {User} from '../../shared/interfaces/user';
import {ANGULAR_JS_EVENTS, APP_USER_STATUS} from '../../shared/constants';
import {AngularJsService} from '../../shared/services/angular-js.service';
import {LoginService} from './login.service';
import {JWTService} from "../../shared/services/JWT.service";
import {Router} from "@angular/router";
import {AppService} from "../../app.service";
import { TranslateService } from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {Preferences} from "@capacitor/preferences";
import {Capacitor} from "@capacitor/core";
import { BehaviorSubject } from 'rxjs';
declare var angular;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends UnsubscribableComponent implements OnInit, OnDestroy {
  public branding: Branding;
  public localeButtonString = 'En';
  errors: any;
  public loggingIn = false;
  isApp = Capacitor.isNativePlatform();
  authData = {
    property: 1,
    email: localStorage.getItem('email'),
    password: '',
    remember_me: !!localStorage.getItem('remember_me'),
    agree_terms: false,
    phone_number: '',
    last_name: '',
    first_name: '',
    // @ts-ignore
    cordova: window.cordova ? 1 : 0
  };
  public db: any = null;

  step = 'login';

  constructor(
    private store: Store<State>,
    protected angularJsService: AngularJsService,
    public loginService: LoginService,
    protected jwtService: JWTService,
    public router: Router,
    private appService: AppService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    super();
    if (window.cordova && window.cordova.platformId === 'ios') {
      this.jwtService.getToken().then(resp => {
        console.log('login token resp', resp);
        this.appService.loadUser().subscribe(resp => {
          console.log('resp', resp.success);
          if (resp.success) {
            this.router.navigateByUrl('/n/dashboard').then(r => {
              const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
              if (angularJsScope) {
                angularJsScope.$state.go('main.ng2', {}, {notify: false});
              } else {
                console.error('Where is angular scope???', angularJsScope);
              }
            });
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);
    this.sub = this.store.select(selectAppLocale).subscribe(locale =>
      this.localeButtonString = locale
    );
    this.setUpCapacitorRememberMe();
  }

  login() {
    this.loggingIn = true
    this.loginService.login(this.authData).subscribe({
      next: (subscr) => {
        this.loggingIn = false;
        if (subscr.success === false) {
          this.errors = subscr.errors.reduce((acc, it) => {
            acc[it] = [it];
            return acc;
          }, {});
        } else {
          if (subscr.data === 'OPT_INSTALLATION_REQUIRED') {
            this.step = '2fa'
          }
        }
      },
      error: (error) => {
        this.errors = error.error.errors;
        this.loggingIn = false;
      }});
  }

  setLocalization(locale: 'en' | 'fr') {
    this.store.dispatch(setLocale({ locale }));
    this.store.dispatch(loadTranslations({ locale }));
    this.angularJsService.fireEvent(ANGULAR_JS_EVENTS.ANGULAR_JS_LANG_CHANGED, locale);
  }

  goForgotPassword() {
    window.location.href = '#/n/password/reset';
  }

  goRegister() {
    if (window.location.host.indexOf('leasing') > 0) {
      window.location.href = window.location.origin.replace('leasing.', '') + '/#/register';
    } else {
      window.location.href = '/#/register';
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  async setUpCapacitorRememberMe() {
    try {
      const rememberMe = await Preferences.get({ key: 'remember_me' });
      if (rememberMe.value !== null) {
        this.authData.remember_me = rememberMe.value === 'true';
      }

      const rememberEmail = await Preferences.get({ key: 'remember_email' });
      if (rememberEmail.value !== null) {
        this.authData.email = rememberEmail.value;
      }
    } catch (error) {
      console.log('Error accessing Preferences:', error);
    }
  }

  showPassword = new BehaviorSubject(false);
  toggleShowPassword() {
    this.showPassword.next(!this.showPassword.getValue());
  }
  
}
