import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../shared/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(
    private http: HttpClient,
  ) { }

  public remind(email: string): Observable<ApiResponse<any>> {
    return this.http.post<any>(environment.domain + '/api/v1/password/remind', { email: email });
  }

  public reset(data: ResetPasswordData): Observable<any> {
    return this.http.post(`${environment.domain}/api/v1/password/reset`, data)
  }
}

export interface ResetPasswordData {
  email: string;
  password: string;
  token: string;
}
