import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class PqValidators extends Validators {
    static notSame(repeatControlName: string): ValidatorFn {
        return notSameValidator(repeatControlName);
    }
}

export function notSameValidator(repeatControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.root.get(repeatControlName)?.value === control.value ? null : { notSame: true };
    };
}