import { Action, createReducer, on } from '@ngrx/store';
import { initialManagersState, ManagersState } from './managers.state';
import { managersLoaded } from './managers.actions';

export function managersReducer(state: ManagersState | undefined, action: Action) {
  return createReducer(
    initialManagersState,
    on(managersLoaded, (currentState: ManagersState, {managers}) => ({...currentState, managers}))
  )(state, action);
}
