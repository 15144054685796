import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { environment } from '../../../environments/environment';
import { MessageListResponse } from './resources/message-list-response.interface';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MessageCreate } from './resources/message-create.interface';
import { MessageModel } from './resources/message.model';
import {Recipient} from "../../shared/interfaces/recipient";
import { TicketPriority } from './resources/ticket-priority.type';
import { TicketStatus } from './resources/ticket-status.type';
import { Ticket } from './resources/ticket.interface';
import { QuillModules } from 'ngx-quill';
declare var pqCachedToken: string;

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  newMessageCount = new BehaviorSubject<number>(0);
  public dataChanged = new EventEmitter();

  constructor(private http: HttpClient) {
    this.newMessageCount.pipe(
      distinctUntilChanged()
    ).subscribe(() => {
      this.dataChanged.emit()
    });
  }

  public list(folder: 'inbox' | 'archived' | 'sent', isProspect = null, page = 1, recipientId = null, filter = null): Observable<MessageListResponse> {
    // &recipient=${recipientId}&filter=${filter}
    const params = new HttpParams()
      .set('folder', folder)
      .set('is_prospect', isProspect)
      .set('page', page)
      .set('limit', 100);

    return this.http.get<MessageListResponse>(`${environment.domain}/api/v1/messages`, {params}).pipe(
      map((response: MessageListResponse) => ({
        ...response,
        messages: response.messages.map((i) => Object.assign(new MessageModel(), i))
      } as MessageListResponse))
    );
  }

  /** send a message from the communications module */
  public submit(data: MessageCreate): Observable<ApiResponse<any>>{
    return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/messages/submit`, data).pipe(
      tap(() => this.dataChanged.emit('create')),
    );
  }

  public isDashboardMessagesEnabled(): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/dashboard-message/is-enabled`, {});
  }

  public markAsArchived(data: number[]): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(`${environment.domain}/api/v1/messages/set-archived`, {ids: data}).pipe(
      tap(() => this.dataChanged.emit())
    );
  }

  public markAsUnarchived(data: number[]): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(`${environment.domain}/api/v1/messages/set-unarchived`, {ids: data}).pipe(
      tap(() => this.dataChanged.emit())
    );
  }

  public markAsRead(data: number[]): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(`${environment.domain}/api/v1/messages/set-read`, {ids: data}).pipe(
      tap(() => this.dataChanged.emit())
    );
  }

  public markAllAsRead(data: number[]): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/messages/set-all-read`, []).pipe(
      tap(() => this.dataChanged.emit())
    );
  }

  public getTags(): Observable<ApiResponse<{name: string}[]>> {
    return this.http.get<ApiResponse<{name: string}[]>>(`${environment.domain}/api/v1/messages/tags`);
  }

  public getSentToData(count_id: number): Observable<{delivered: string[], missing: string[]}> {
    return this.http.get<{delivered: string[], missing: string[]}>(`${environment.domain}/api/v1/messages/get-count-data/${count_id}`);
  }

  public forwardProspectEmailDetails(messageId, managerId): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/messages/${messageId}/forward`, {manager_id: managerId});
  }

  public getFilteredRecipients(query: string, recipientType: 'tenant' | 'prospect'): Observable<Recipient[]> {
    const params = new HttpParams()
      .set('query', query)
      .set('recipientType', recipientType);
    return this.http.get<Recipient[]>(`${environment.domain}/api/v1/messages/search-user`, { params });
  }

  public getThread(id: number): Observable<ApiResponse<MessageModel[]>> {
    return this.http.get<ApiResponse<MessageModel[]>>(`${environment.domain}/api/v1/messages/thread/${id}`);
  }

  public getTicketTypes(): Observable<ApiResponse<{id: number, name: string, description: string}[]>> {
    return this.http.get<ApiResponse<{id: number, name: string, description: string}[]>>(`${environment.domain}/api/v1/messages/categories`);
  }

  public getTicketTags(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${environment.domain}/api/v1/messages/tags`);
  }

  public getManagers(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${environment.domain}/api/v1/messages/managers`);
  }

  public updateTicket(message_id: number, type_id: number, tag_ids: number[], manager_id: number, priority: TicketPriority, status: TicketStatus, notes: string): Observable<Ticket> {
    return this.http.put<Ticket>(`${environment.domain}/api/v1/messages/update-ticket`, {
      message_id,
      type_id,
      tag_ids,
      manager_id,
      priority,
      status,
      notes
    });
  }

  public getCompanies(): Observable<ApiResponse<any[]>> {
    return this.http.get<ApiResponse<any[]>>(`${environment.domain}/api/v1/messages/companies`);
  }

  public getProperties(companyIds: number[]): Observable<ApiResponse<any[]>> {
    return this.http.post<ApiResponse<any[]>>(`${environment.domain}/api/v1/messages/properties`, { company_ids: companyIds });
  }

  public archive(message_id: number): Observable<any> {
    return this.http.put<any>(`${environment.domain}/api/v1/messages/set-archived`, { ids: [message_id] });
  }

  public attachmentUrl(attachmentId: number): string {
    return `${environment.domain}/message-attachment/${attachmentId}?token=${pqCachedToken}`;
  }

  public defaultQuillFormats: string[] = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image' // drag and drop image handled by attachments, too large for body
    // 'video'
  ];

  public defaultQuillModules: QuillModules = {
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler() {
            return true;
          },
        },
      },
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true
    },
    toolbar: {
      container: [
        ['undo', 'redo'],
        [
          { header: 1 },
          { header: 2 },
        ],
        ['bold', 'italic', 'underline', 'strike', { list: 'ordered' }, { list: 'bullet' }],
      ],
      handlers: {
        undo: function () {
          this.quill.history.undo();
        },
        redo: function () {
          this.quill.history.redo(); 
        },
      }
    },
  };
}