import { Component, OnInit } from '@angular/core';
import { TermsService } from '../terms.service';
import { CommonModule } from '@angular/common';
import { LayoutService } from '../../../services/layout.service';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-terms-dialog',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './terms-dialog.component.html',
  styleUrl: './terms-dialog.component.scss'
})
export class TermsDialogComponent implements OnInit {
  public isCordova = !!window.cordova;

  padAgreementBody: string = '';
  lastUpdatedOn: string | null = null;

  constructor(
    private termsService: TermsService,
    private layoutService: LayoutService,
    ) {}

  ngOnInit(): void {
    this.termsService.getPadAgreement().subscribe(response => {
      if (response.result) {
        this.padAgreementBody = response.result.body;
        this.lastUpdatedOn = response.result.revision_date.split(' ')[0];
      }
    });
  }

  printOpen() {
    this.layoutService.isPrint.next(true);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

  printClose() {
    this.layoutService.isPrint.next(false);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

}
