<h2 mat-dialog-title>
    <b>PRIVACY POLICY</b>

    <button class="no-print" mat-icon-button [printTitle]="'PAYQUAD PRIVACY POLICY'" [matTooltip]="'Print' | translate"
        printSectionId="privacy-policy-print" [useExistingCss]="true" [printDelay]="100" [clickDelay]="100"
        (click)="printOpen()" (printClose)="printClose()" *ngIf="!isCordova" ngxPrint>
        <mat-icon svgIcon="pq:printer"></mat-icon>
    </button>
</h2>
<mat-dialog-content id="privacy-policy-print">
    <h1 class="wp-block wp-block-post-title block-editor-block-list__block editor-post-title editor-post-title__input rich-text"
        style="text-align: center" role="textbox" contenteditable="true" aria-label="Add title" aria-multiline="true">
        Privacy Policy &amp; Terms
    </h1>
    <p><b>OUR PRIVACY POLICY (EXTERNAL)</b></p>
    <p>
        <span>We value the trust that you have placed in Payquad Solutions Inc.
            (“</span><b>we</b><span>”, “</span><b>our</b><span>”, or “</span><b>us</b><span>”) and protecting your
            personal information is our priority. This Privacy
            Policy (“</span><b>Policy</b><span>”) discloses the practices of Payquad Solutions Inc. and its affiliates
            (collectively, “</span><b>Payquad</b><span>”), regarding the collection, use, safeguard, disclosure,
            transfer,
            access, disposal and other processing (collectively, “</span><b>Processing</b><span>”) of personal
            information of individuals who engage with Payquad for our
            support, services or otherwise, or visit our website(s) (“</span><b>Website</b><span>”) or contribute to
            Payquad social media platforms. For the purposes of
            this Policy, personal information (“</span><b>Personal</b> <b>Information</b><span>”) shall mean information
            that can identify an individual directly or
            indirectly through reasonably available information, but does not include
            business contact information that is used solely for the purpose of
            communicating or facilitating communication with an individual in relation
            to their employment, business, or profession.</span>
    </p>
    <p><b>ACCEPTANCE OF PRIVACY POLICY</b></p>
    <p>
        <span>By providing Personal Information to Payquad, and visiting the Website
            and clicking “I have read and Agree to the Terms of Service and Privacy
            Policy”, engaging with our social media or otherwise, you are consenting
            to our Processing of your Personal Information in accordance with the
            terms of this Policy.&nbsp;</span>
    </p>
    <p>
        <span>This Policy is effective as of the “Revision Date” in the footer, and
            will remain in effect except with respect to any of its provisions that
            are changed in the future. We reserve the right to change this Policy at
            any time. Changes, modifications, additions, or deletions will be
            effective immediately upon their posting to the Website or upon you being
            otherwise notified for all go forward interactions. Your continued use of
            the Website or our other Services after we post any such modifications
            will constitute your acknowledgement of the modified Policy and your
            agreement to abide and be bound by the modified Policy on a go forward
            basis. We will also update the “Revision Date” when we post substantive
            changes to the Policy.&nbsp;</span>
    </p>
    <p><b>RIGHT TO OPT OUT</b></p>
    <p>
        <span>Subject to certain legal and contractual limitations, you have the right
            to withdraw your consent from us Processing your Personal Information.
            This may limit our ability to provide you with our services, act on your
            behalf or engage with you as you would like. To withdraw your consent to
            certain Processing by Payquad you may declare to the Designated Privacy
            Official (contact information provided below) in writing, at any time, of
            your desire to withdraw consent. Payquad will inform you of the
            implications of such withdrawal within thirty (30) days of your written
            request. Any withdrawal of consent will apply thereafter and not to
            information handling practices that have been previously undertaken based
            on prior consent.&nbsp;</span>
    </p>
    <p><b>MINORS</b></p>
    <p>
        <span>Payquad will not knowingly obtain consent from those individuals who are
            minors, or those who are not otherwise able to legally consent to terms.
            If Payquad has a concern about this, we shall seek consent from a person
            having legal authority in relation to or a power of attorney of such an
            individual.&nbsp;</span>
    </p>
    <p><b>COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION</b></p>
    <p><b>Types of Personal Information Collected and Processed</b></p>
    <p>
        <span>The Personal Information which Payquad may collect includes, but is not
            limited to:</span>
    </p>
    <ol>
        <li aria-level="1">
            <span>Contact and identifying information including your name, mailing
                address, email address and telephone number.</span>
        </li>
        <li aria-level="1">
            <span>Login information including username and password.</span>
        </li>
        <li aria-level="1">
            <span>Transaction information including details of commercial or other
                transactions.&nbsp;</span>
        </li>
        <li aria-level="1">
            <span>Payment information including your credit card details, bank account
                information, and payment or other information as required.</span>
        </li>
        <li aria-level="1">
            <span>User information including messages and images uploaded / shared as
                part of a public forum, message boards, requests and user chats.</span>
        </li>
        <li aria-level="1">
            <span>Information regarding your lease application and lease agreement with
                your landlord and other terms of your lease needed to provide the
                service including any supporting documents or government ID that you
                upload.</span>
        </li>
        <li aria-level="1">
            <span>Marketing and communications information including your preferences in
                receiving marketing from us and third parties, and your communication
                preferences.</span>
        </li>
        <li aria-level="1">
            <span>Computer information including device type, device identifiers, IP
                address, MAC address, location, browser type, operating system and
                platform, protocol, sequence information, cookies, beacons, pixel tags,
                browser language and type, and domain name system requests.</span>
        </li>
        <li aria-level="1">
            <span>Internet or other electronic network activity including browsing,
                session, interaction, search history, duration of use, frequency of use,
                material and pages viewed, time and date of access, number of bytes
                transferred, number of clicks per visit and other user behaviour related
                to our website.</span>
        </li>
        <li aria-level="1">
            <span>Other information which you voluntarily provide to us, our employees,
                or our contractors.</span>
        </li>
    </ol>
    <p><b>Purposes for Collection and Processing of Personal Information</b></p>
    <p>
        <span>Personal Information may be collected by Payquad for purposes that
            include, but are not limited to:&nbsp;</span>
    </p>
    <ol>
        <li aria-level="1">
            <span>Maintaining and improving our services and operations, and otherwise
                running and managing our business in the ordinary course, and keeping
                our records up to date.</span>
        </li>
        <li aria-level="1">
            <span>Fulfilling our contractual and business obligations, including with
                suppliers and contractors, and accessing and using services and the
                Website.</span>
        </li>
        <li aria-level="1">
            <span>Receiving communications from us in regard to e-news, emails,
                bulletins, notifications, newsletters, programs, events and
                activities.</span>
        </li>
        <li aria-level="1">
            <span>Assisting you when you contact us, including to direct your questions
                to appropriate individuals, investigate and address any of your
                concerns, and to improve and monitor our responses.</span>
        </li>
        <li aria-level="1">
            <span>Administering and protecting our business and Website, including
                troubleshooting, data analysis, testing, system maintenance, support,
                reporting and hosting of data.&nbsp;</span>
        </li>
        <li aria-level="1">
            <span>Marketing and advertising to be presented on the Website.&nbsp;</span>
        </li>
        <li aria-level="1">
            <span>Administering surveys and other promotional materials to you based on
                your interests.</span>
        </li>
        <li aria-level="1">
            <span>Using data for business development and market research to understand
                demographics, interests, usage patterns, and other characteristics of
                individuals and to track and analyze trends and patterns.</span>
        </li>
        <li aria-level="1">
            <span>Complying generally with all laws and applicable statutory
                requirements.&nbsp;</span>
        </li>
    </ol>
    <p><b>Methods of Collection of Personal Information</b></p>
    <p><b>a. General</b></p>
    <p>
        <span>Personal Information may be collected by Payquad in a number of ways,
            including in person, our website, by mail, by email, from our applications
            or other software, and from third parties whom you have authorized to
            disclose Personal Information to us.</span>
    </p>
    <p>
        <span>If you provide information about a third party or authorize a third party
            to do so, we will assume you have taken proper measures to obtain informed
            consent.</span>
    </p>
    <p><b>b. Phone Calls</b></p>
    <p>
        <span>For the purpose of maintaining quality, telephone calls to Payquad lines
            may be recorded. If your call is subject to a quality assurance program,
            you will be advised prior to speaking to a representative.</span>
    </p>
    <p><b>c. Cookies and Web Beacons</b></p>
    <p>
        <span>Payquad may collect Personal Information through cookies on our Website.
            Cookies are small text files inside your computer that contain a unique
            identifier, allowing us to recognize you when you visit our Website again.
            This helps us to understand your preferences based on previous or current
            Website activity, allowing us to provide you with improved services,
            tailor our content to meet your needs and for advertising purposes. We
            also may use cookies and navigational data to gather information regarding
            the date and time of your visit and the information for which you searched
            and viewed, or on which of the advertisements displayed on our Website you
            clicked.&nbsp;</span>
    </p>
    <p>
        <span>The Website may use retargeting ads to advertise online. Retargeting uses
            cookies from third party vendors like Google to track visitors. This
            tracking enables the third-party vendors to display our ads to people on
            various sites across the internet based on their visits to our
            Website.&nbsp;</span>
    </p>
    <p>
        <span>We may also use web beacons (small pieces of data that are embedded in
            images on the pages of our Website) and auditing software to track page
            views and entry and exit points to and from our Website. We may use web
            beacons, cookies, customized links and/or similar technologies to
            determine whether electronic newsletters sent by us to those who have
            requested them from us have been opened and which links are clicked. Any
            data collected will only be used in an aggregate form and will not contain
            any Personal Information.</span>
    </p>
    <p>
        <span>Some cookies are automatically deactivated upon your access and use of
            the Website. However, unless you activate cookies, you may be unable to
            fully utilize and access all areas and features of the Website. You may
            activate cookies by visiting our Website and clicking “Activate Cookies”.
            Some third-party service providers may also place their own cookies on
            your browser. Note that this Policy only covers Payquad’s use of cookies
            and does not include use of cookies by such third parties.</span>
    </p>
    <p><b>d. Clickstream Data</b></p>
    <p>
        <span>When you visit the Website, we may also collect clickstream data (e.g.
            server address, domain name, device type, browsing software) which may be
            stored on the Website’s server. This information can be combined with
            information you have provided to us which will enable us to analyze and
            better customize your visits. We may use clickstream data for traffic
            analysis or e-commerce analysis of the Website, to determine which
            features of the Website are most or least effective or useful to
            you.</span>
    </p>
    <p><b>e. Mobile and Location Information</b></p>
    <p>
        <span>Payquad’s Website features may be accessible from web-enabled mobile
            devices. The intent is to provide screens that are optimized for the size
            of the screen and operating systems. On some devices, functionality may be
            limited. If you use mobile-enabled services provided by our Website, we
            may receive information about you from your mobile device. This may also
            include information about your precise location if you have enabled
            location-based services on your mobile device. Some devices may allow you
            to opt-out of this collection or access, and in those cases, you may be
            able to subsequently stop the collection or access. Please review your
            device settings for more information.</span>
    </p>
    <p><b>f. Social Media</b></p>
    <p>
        <span>If you post a review, make a comment, or otherwise submit Personal
            Information on a public forum such as social media accounts or public
            forums on our Website or other applications, your communications may be
            viewable by the public. When voluntarily disclosing Personal Information
            about yourself with other Payquad users, we are unable to control what may
            be done with that content. You should take all necessary precautions to
            protect your private information by not posting or publishing any
            information that you do not want in the public domain. While we strive to
            provide the highest level of confidentiality, you should review your
            personal privacy settings to further restrict any or all parts of your
            profile or other certain content or information.&nbsp;</span>
    </p>
    <p><b>g. Other</b></p>
    <p>
        <span>Payquad may collect Personal Information when you interact with our
            Website, including when you access the Website, communicate with Payquad
            sales staff, utilize services from Payquad and or its related&nbsp; group
            of companies, purchase goods or services, register or create or edit your
            account, sign up to receive newsletters and information, fill out online
            applications, surveys or forms, send or respond to our emails, or
            otherwise communicate with us.&nbsp;</span>
    </p>
    <p><b>Sharing of Personal Information with Third Parties</b></p>
    <p>
        <span>Although you are entering into an agreement to disclose your Personal
            Information to Payquad pursuant to this Policy, we may delegate our
            authority for Processing of your Personal Information to third party
            individuals and organizations who assist us with providing our services
            and administering our business, and for such other purposes as set forth
            below, including:</span>
    </p>
    <ol>
        <li aria-level="1">
            <span>Contractors, including companies we use for storage, processing, and
                delivery of services.</span>
        </li>
        <li aria-level="1">
            <span>Administrative and technical support, including cloud storage
                providers, IT support, and data analytics providers.</span>
        </li>
        <li aria-level="1">
            <span>Public media and marketing, including companies we use for advertising,
                marketing, public relations, and press releases.</span>
        </li>
        <li aria-level="1">
            <span>Parties in connection with proposed or actual financing, insuring,
                sale, securitization, assignment or other disposal of all or part of our
                business or assets or to a person who is looking to acquire all or part
                of the shares in the Payquad.</span>
        </li>
    </ol>
    <p>
        <span>We may also disclose Personal Information in situations where we are
            legally required or permitted to do so. These situations may include
            credit checks, sales personnel, third party sales management personnel,
            government tax reporting requirements and any other relevant government
            information required relating to the sale and purchase of real
            estate.</span>
    </p>
    <p>
        <span>When signing in as a prospective tenant, your property management company
            may collect other Personal Information to help them assess your
            suitability to become a tenant. This may include, financial information,
            employee information, identification, past tenancy history or other such
            Personal Information.</span>
    </p>
    <p>
        <span>If you believe that a third party has inappropriately disclosed your
            Personal Information to us, please contact that third party directly. If
            the third party does not sufficiently respond to your inquiries, please
            let us know immediately.</span>
    </p>
    <p><b>MANAGING YOUR PERSONAL INFORMATION</b></p>
    <p><b>Accuracy of Personal Information</b></p>
    <p>
        <span>Payquad takes all reasonable steps to keep your Personal Information as
            accurate, complete and up-to-date as necessary to fulfill the purpose for
            which your Personal Information has been collected. If desired, you may
            verify the accuracy and completeness of your Personal Information in our
            records with our Designated Privacy Official.&nbsp;</span>
    </p>
    <p>
        <span>Despite our efforts, errors sometimes do occur. Should you identify any
            incorrect or out-of-date Personal Information in your file, we will remedy
            any such errors on a timely basis. You may request correction of the
            Personal Information we hold about you, though we may need to verify the
            accuracy of the new information you provide to us. If inaccurate Personal
            Information is mistakenly sent to a third party, we will communicate
            relevant changes to the third party where appropriate.</span>
    </p>
    <p><b>Retention of Personal Information</b></p>
    <p>
        <span>Payquad will store your Personal Information only for as long as is
            reasonably necessary to fulfill the purpose for which the Personal
            Information was collected, including for the purposes of satisfying any
            legal, accounting, or reporting requirements or obligations of
            Payquad.&nbsp;</span>
    </p>
    <p>
        <span>To determine the appropriate retention period for Personal Information,
            we consider the amount, nature, and sensitivity of the Personal
            Information, the potential risk of harm from unauthorized use or
            disclosure of your Personal Information, the purposes for which we process
            your Personal Information and whether we can achieve those purposes
            through other means, and the applicable legal requirements.</span>
    </p>
    <p>
        <span>In some circumstances we may anonymize your Personal Information (so that
            it can no longer be associated with you) for research or statistical
            purposes in which case we may use this anonymized information indefinitely
            without further notice to you.</span>
    </p>
    <p><b>Request for Access to Personal Information and Processing</b></p>
    <p>
        <span>You may request access to your Personal Information we have collected
            from you and information about how we are Processing it. We may need to
            request specific information from you to help us confirm your identity and
            right to access your Personal Information. This is a security measure to
            ensure that Personal Information is not disclosed to any person who has no
            right to receive it. Payquad may not always be able to comply with your
            request for access to Personal Information for specific reasons which you
            will be notified of, if applicable, at the time of your request.</span>
    </p>
    <p><b>Request for Deletion or Removal of Personal Information</b></p>
    <p>
        <span>In some circumstances you have the right to request that we delete or
            remove your Personal Information where there is no good reason for us to
            continue Processing it. You may also ask us to delete or remove your
            Personal Information where you have successfully exercised your right to
            object to Processing, where we may have processed your information
            unlawfully or where we are required by law to erase your Personal
            Information. We may not always be able to comply with your request for
            deletion of Personal Information for specific legal reasons which you will
            be notified of, in writing, within thirty (30) days of the time of your
            request.</span>
    </p>
    <p><b>PROTECTING YOUR PERSONAL INFORMATION</b></p>
    <p><b>Integrity and Security</b></p>
    <p>
        <span>Payquad uses industry standard measures, including administrative,
            organizational, technical, and physical safeguards, to help protect
            Personal Information from loss, theft, misuse, and unauthorized access,
            disclosure, alteration, and destruction. We implement measures and
            processes to help us to keep your Personal Information secure and to
            maintain its quality, such as keeping electronic files in a secured
            environment with restricted access and use. Personal Information that
            comprises of credit and debit card numbers are tokenized and stored
            off-site on Payment Card Industry Data Security Standard (PCI-DSS)
            compliant servers.</span>
    </p>
    <p>
        <span>We regularly review our security and related policies to adapt the
            technology as new threats evolve and monitor our systems to help ensure
            the highest level of availability. If you have any questions about the
            security of our Website or your Personal Information, you can reach out to
            our Designated Privacy Official.</span>
    </p>
    <p>
        <span>Access to private, sensitive and confidential information, including
            Personal Information, is restricted to authorized employees or contractors
            with legitimate business reasons. Payquad employees and contractors
            understand the importance of keeping your Personal Information private.
            All employees and contractors are expected to maintain the confidentiality
            of Personal Information.</span>
    </p>
    <p><b>Risks&nbsp;</b></p>
    <p>
        <span>Despite the foregoing security measures and significant steps Payquad has
            taken to protect your Personal Information, no company can fully eliminate
            all security risks associated with the Processing of Personal Information.
            The level of risk corresponds with the nature of the information at issue
            and how it is used. With that in mind, we cannot guarantee the security of
            any Personal Information provided to or received by us. We encourage you
            to provide only the Personal Information you are comfortable with
            providing to a third party, keep watch for communications that are
            suspicious, and report any suspicious activity to us as soon as
            possible.&nbsp;</span>
    </p>
    <p><b>WEBSITE AND APPLICATIONS GOVERNED BY THIS POLICY</b></p>
    <p>
        <span>Our Website and our applications available from our Website or
            third-party platforms (including Google Play), if any, are governed by the
            provisions and practices stated in this Policy. Our Website and such
            applications may contain links to third party sites or applications that
            are not governed by this Policy. Although we endeavour to only link to
            sites or applications that share our commitment to your privacy, please be
            aware that this Policy will no longer apply once you leave our Website or
            such applications, and that we are not responsible for the privacy
            practices of third party sites or applications. We therefore suggest that
            you closely examine the respective privacy policies of third-party sites
            and applications to learn how they collect, use and disclose your Personal
            Information.</span>
    </p>
    <p><b>GOVERNING LAW</b></p>
    <p>
        <span>This Policy and all related matters shall be interpreted and construed in
            accordance with the laws of the Province of Ontario and the applicable
            federal laws of Canada.&nbsp;</span>
    </p>
    <p><b>PERSONAL INFORMATION OUTSIDE OF CANADA</b></p>
    <p>
        <span>Personal Information provided to our service providers may be stored,
            processed, used and accessed outside of Canada. You acknowledge and agree
            that, as a result, your Personal Information may be processed, used,
            stored or accessed in other jurisdictions and may be subject to the laws
            of those jurisdictions. For example, information may be disclosed in
            response to valid demands or requests from government authorities, courts,
            or law enforcement in other countries. If you are a Quebec resident,
            please be advised that your Personal Information is processed outside of
            Quebec.</span>
    </p>
    <p><b>ADDRESSING YOUR INQUIRIES AND CONCERNS</b></p>
    <p>
        <span>Your privacy is very important to us. We are happy to provide you with a
            copy of this Policy and to discuss any of its contents with
            you.&nbsp;</span>
    </p>
    <p>
        <span>Payquad’s Designated Privacy Official is responsible for the
            implementation of this Policy and monitoring our adherence to its terms
            and all applicable laws. The Designated Privacy Official also handles
            questions and concerns about our Policy, as well as Personal Information
            access requests and complaints. The Designated Privacy Official may be
            contacted at:</span>
    </p>
    <p><span>Designated Privacy Official, Payquad Solutions Inc.</span></p>
    <p><span>42 Dufflaw Rd Suite 2000</span></p>
    <p><span>Toronto, Ontario M6A 2W1</span></p>
    <p><span>1 888 385 9037</span></p>
    <p>
        <a href="mailto:privacy@payquad.com"><span>{{ "privacy@payquad.com" }}</span></a>
    </p>
    <p>&nbsp;</p>
    <p>LAST UPDATED: June 6, 2024</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close color="warn">
        {{ "Close" | translate }}
    </button>
</mat-dialog-actions>