import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UnsubscribableComponent } from '../../classes/abstract/unsubscribable-component';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { first, map, take } from 'rxjs/operators';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: ["./date-selector.component.scss"],
  providers: [{
    provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
    useClass: DefaultMatCalendarRangeStrategy
  }]
})
export class DateSelectorComponent extends UnsubscribableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() output = new EventEmitter<[Date, Date]>();
  @Input() initialValue: Observable<[Date, Date] | string> = new Observable();

  @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<Date>;

  isOpen = false;

  timeOptions = [
    { value: "none", display_name: this.translate.instant("All Time") },
    { value: "today", display_name: this.translate.instant("Today") },
    { value: "yesterday", display_name: this.translate.instant("Yesterday") },
    { value: "current-month", display_name: this.translate.instant("This Month") },
    { value: "next-month", display_name: this.translate.instant("Next Month") },
    { value: "last-7-day", display_name: this.translate.instant("Last 7 Days") },
    { value: "last-30-day", display_name: this.translate.instant("Last 30 Days") },
    { value: "custom", display_name: this.translate.instant("Custom") }
  ];

  selectedDateOption = new BehaviorSubject<{ value: string, display_name: string }>(this.timeOptions[0]);
  OGSelectedDateOption = new BehaviorSubject<{ value: string, display_name: string }>(this.timeOptions[0]);

  selectedRangeValue: DateRange<Date> | undefined;
  OGSelectedRangeValue: DateRange<Date> | undefined;

  constructor(
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedDateOption.pipe(
      map(option => option.value)
    ).subscribe(value => {
      const [startDate, endDate] = this.getDateRangeFromDateOptionString(value);

      // console.log("startDate", startDate); console.log("endDate", endDate);

      if (this.selectedRangeValue?.start !== startDate || this.selectedRangeValue?.end !== endDate) {
        this.selectedRangeValue = new DateRange<Date>(startDate, endDate);
        startDate && this.calendar ? this.calendar._goToDateInView(startDate, 'month') : null;
      };

      if(value !== 'custom') {
        this.applyAndClose();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initialValue.pipe(
      take(1)
    ).subscribe(initialValue => {
      if(typeof initialValue === 'string'){
        this.selectedDateOption.next(this.timeOptions.find(option => option.value === initialValue));
      } else if (Array.isArray(initialValue) && initialValue.length === 2 && initialValue.every(item => item instanceof Date)) {
        this.selectedDateOption.next(this.timeOptions.find(option => option.value === 'custom'));
        this.selectedRangeValue = new DateRange<Date>(initialValue[0], initialValue[1]);
        initialValue[0] && this.calendar ? this.calendar._goToDateInView(initialValue[0], 'month') : null;
        this.output.emit(initialValue);
      }
    })
  }

  getDateRangeFromDateOptionString(value: string): [Date, Date] {
    let startDate: Date;
    let endDate: Date;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    switch(value) {
      case 'none': {
        startDate = null;
        endDate = null;
        break;
      }

      case 'today': {
        startDate = new Date();
        endDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }

      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        startDate = yesterday;
        endDate = new Date(yesterday);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }

      case 'current-month': {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }

      case 'next-month': {
        startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }

      case 'last-7-day': {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);

        startDate = sevenDaysAgo;
        startDate.setHours(0, 0, 0, 0);
        endDate = today;
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case 'last-30-day': {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);

        startDate = thirtyDaysAgo;
        startDate.setHours(0, 0, 0, 0);
        endDate = today;
        endDate.setHours(23, 59, 59, 999);
        break;
      }

      case 'custom': {
        startDate = this.selectedRangeValue?.start;
        endDate = this.selectedRangeValue?.end;
        break;
      }
    }
    return [startDate, endDate];
  }

  toggleOverlay() {
    this.isOpen = !this.isOpen;
  }

  saveState() {
    this.OGSelectedRangeValue = this.selectedRangeValue;
    this.OGSelectedDateOption.next(this.selectedDateOption.getValue());
  }

  selectTimeOption(value: { value: string; display_name: string }) {
    this.selectedDateOption.next(value);
  }

  selecteDateRangeChange(event: any) {
    this.selectedDateOption.next(this.timeOptions.find(option => option.value === 'custom'));

    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(new Date(event), null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = event;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
  }

  cancel() {
    this.selectedRangeValue = this.OGSelectedRangeValue;
    this.selectedDateOption.next(this.OGSelectedDateOption.getValue());
    this.isOpen = false;
  }

  clearAll() {
    this.selectedDateOption.next(this.timeOptions.find(option => option.value === 'none'));
  }

  applyAndClose() {
    if (this.selectedRangeValue.start && !this.selectedRangeValue.end) {
      const endDate = new Date(this.selectedRangeValue.start);
      endDate.setHours(23, 59, 59);
      this.selectedRangeValue = new DateRange<Date>(this.selectedRangeValue.start, endDate);
    }
    this.output.emit([this.selectedRangeValue.start, this.selectedRangeValue.end]);
    this.saveState();
    this.isOpen = false;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
