<div class="wrapper">
    <div class="companyicon">
        <img ng-hide="branding.hideCompanyLogo" [src]="branding.logo_file_url"/>
    </div>

    <mat-card class="form-mat-card">
        <div class="title">{{'Reset Password' | translate}}</div>
        <form class="pq-form" [formGroup]="formGroup">
            <label>{{'Email' | translate}}</label>
            <mat-form-field class="full-width-field">
                <input matInput placeholder="example@domain.com" formControlName="email">
                <mat-error>
                    <span *ngIf="formGroup.controls['email'].touched && formGroup.controls['email'].errors?.required">{{'Email is required field' | translate}}</span>
                    <span *ngIf="formGroup.controls['email'].touched && formGroup.controls['email'].errors?.email">{{'Not valid email' | translate}}</span>
                </mat-error>
            </mat-form-field>
            
            <button
                mat-flat-button
                color="primary"
                class="full-width-field send-button"
                (click)="send()"
                [disabled]="(disableButton | async) || ! formGroup.valid"
            >{{'Send Reset Link' | translate}}</button>

            <div class="notice-container">
                {{'Already have an account?' | translate}} <a class="notice-link" href="/#/n/login"> {{'Login' | translate}}</a>
            </div>
        </form>
    </mat-card>
</div>