import {Action, createReducer, on} from '@ngrx/store';
import {initialMessagesState, MessagesState} from './messages.state';
import {removeScheduled, setIsDashboard, setScheduled} from './messages.actions';

export function messagesReducer(state: MessagesState | undefined, action: Action) {
  return createReducer(
    initialMessagesState,
    on(setScheduled, (currentState: MessagesState, {messages}) => ({
      ...currentState,
      scheduled: messages
    })),
    on(setIsDashboard, (currentState: MessagesState, {isEnabled}) => ({
      ...currentState,
      isDashboardEnabled: isEnabled
    })),
    on(removeScheduled, (currentState: MessagesState, {id}) => ({
      ...currentState,
      scheduled: currentState.scheduled.filter(item => +item.id !== +id)
    }))
  )(state, action);
}
