import { Action, createReducer, on } from "@ngrx/store";
import { initialDashboardState, DashboardState } from "./dashboard.state";
import  * as actions from './dashboard.actions';

export function dashboardReducer(state: DashboardState | undefined, action: Action) {
  return createReducer(
    initialDashboardState,
    on(actions.setPropertyOptions, (currentState: DashboardState, {properties}) => ({
      ...currentState, PropertyFilterOptions: properties
    })),
    on(actions.setSelectedProperties, (currentState: DashboardState, { property_ids }) => ({
      ...currentState, selectedProperties: property_ids
    })),
  )(state, action);
}
