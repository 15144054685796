import {ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {GuardsCheckEnd, NavigationEnd, NavigationStart, Router, RouterEvent, RoutesRecognized, Event as Event_2} from '@angular/router';
import {tap} from 'rxjs/operators';
import {RouterHelper} from './shared/helpers/router-helper';
import {AngularJsService} from './shared/services/angular-js.service';
import {ANGULAR_JS_EVENTS, APP_USER_STATUS} from './shared/constants';
import {Store} from '@ngrx/store';
import {State} from './store/app.state';
import {loadTranslations, loadUser, setLocale, setUser, logout, sessionExpired} from './store/app.actions';
import {Subject} from 'rxjs';
import {LayoutService} from './shared/services/layout.service';
import {MessagesService} from './modules/messages/messages.service';
import {LoginService} from "./authentication/login/login.service";
import {AppVersionService} from "./app-version.service";
import {TranslateService} from "@ngx-translate/core";
import { IconRegistryService } from './icon-registry.service';
import {User} from "./shared/interfaces/user";

declare var angular;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public isAngular2 = false;
  public isLoading = false;
  private isRouteRecognized = false;
  public authUser: User | null;

  routerSubject: Subject<string> = new Subject<string>();

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.layoutService.cssMaxWidth.next(parseInt(e?.target?.innerWidth, 10));
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: Event) {
    const target: any = event.target as any;
    this.layoutService.scrollTop.next(target.documentElement.scrollTop);
  }

  constructor(
    private router: Router,
    protected angularJs: AngularJsService,
    private store: Store<State>,
    // tslint:disable-next-line:variable-name
    private _ngZone: NgZone,
    public layoutService: LayoutService,
    public messagesService: MessagesService,
    public loginService: LoginService,
    public appVersionService: AppVersionService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private iconRegistryService: IconRegistryService,
  ) {
    // console.log('app component constructor');
    // Assign the observable to the window object, so that we can call it from outside
    // @ts-ignore
    window.routerSubject = this.routerSubject;

    this.iconRegistryService.registerIcons();
  }

  ngOnInit() {
    // console.log('app component ngOnInit');
    // this.store.select(selectAppUser).subscribe(user => {
    //   let u = this.authUser = user !== APP_USER_STATUS.NOT_AUTHORIZED ? user : null;
    //
    //   if (u) {
    //     /**
    //      * offline capability (capacitor)
    //      */
    //     // this.inspectionService.cacheInspections();
    //     // this.inspectionService.cacheInspectionTemplates();
    //     // this.propertyService.cacheProperties();
    //     console.log('cached inspections loaded')
    //   }
    // });

    this.routerSubject.subscribe((url: string) => {
      console.log('this.routerSubject.subscribe', url)
      // Programmatically navigate whenever a new url gets emitted.
      this._ngZone.run(() => {
        this.router.navigateByUrl(`/n/${url}`);
      });
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.SIGN_OUT, () => {
      this.store.dispatch(logout());
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.SESSION_EXPIRED, () => {
      this.store.dispatch(sessionExpired());
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.ANGULAR_JS_ROUTE_IS_OPENED, () => {
      this.isAngular2 = false;
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.SIGN_IN, (args) => {
      console.error('SIGN_IN', args);
      this.store.dispatch(loadUser());
      setTimeout(() => {
        this.angularJs.fireEvent(ANGULAR_JS_EVENTS.USER_LOADED, [true]);
      }, 1);
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.SIDENAV_TOGGLE, () => {
      this.layoutService.sidenavOpened.next(!this.layoutService.sidenavOpened.getValue());
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.NEW_MESSAGES, (args) => {
      this.messagesService.newMessageCount.next(args);
    });

    this.angularJs.subscribe(ANGULAR_JS_EVENTS.LOG_IN_AFTER_RESET_PASSWORD, (args) => {
      const authData = {
        property: 1,
        email: args.email,
        password: args.password,
        remember_me: 1,
        agree_terms: false,
        phone_number: '',
        last_name: '',
        first_name: '',
        // @ts-ignore
        cordova: window.cordova ? 1 : 0
      };
      this.loginService.login(authData).subscribe(resp => {});
    });

    this.router.events
      .pipe(
        tap(e => {
          // console.log( e);
        })
      )
      .subscribe((e: RouterEvent|Event_2) => {
        if (e instanceof NavigationStart) {
          this.isLoading = true;
        } else if (e instanceof RoutesRecognized) {
          this.isRouteRecognized = true;
        } else if (e instanceof GuardsCheckEnd) {
          if (!e.shouldActivate) {
          this.isAngular2 = false;
            this.isLoading = false;
          }
        } else if (e instanceof NavigationEnd) {

          const angularJs = angular.element(document.getElementById('angularjs-container')).parent().scope();
          if (angularJs && angularJs.localeButtonString) {
            const angularJsLocale = angularJs.localeButtonString.toLowerCase();
            this.store.dispatch(setLocale({locale: angularJsLocale}));
            this.store.dispatch(loadTranslations({locale: angularJsLocale}));
            this.translate.use(angularJsLocale);
          }

          this.isAngular2 = this.isRouteRecognized && RouterHelper.isAngular2Url(e.url);
          this.isRouteRecognized = false;
          this.isLoading = false;
          if (!this.isAngular2) {
            const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
            if (angularJsScope && angularJsScope.$state.$current.url) {
              let angularJSUrl = angularJsScope.$state.$current.url.source;
              for (const prop in angularJsScope.$state.params) {
                angularJSUrl = angularJSUrl.replace(`{${prop}}`, angularJsScope.$state.params[prop]);
              }
              if (angularJSUrl === e.url) {
                console.log('reload', e.url);
                angularJsScope.$state.reload();
              } else {
                // console.log('not reload', angularJsScope, e);
              }
            }
          }
        }

        this.cdr.detectChanges(); // to remove ExpressionChangedAfterItHasBeenCheckedError re: isLoading
      });
  }

  ngOnDestroy(): void {
    this.routerSubject.unsubscribe();
  }

  public readonly window = window;
}
