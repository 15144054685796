import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ArrayHelper } from '../../helpers/array-helper';
import { distinctUntilChanged, map, shareReplay, take, tap } from 'rxjs/operators';
import { PropertyService } from 'src/app/modules/properties/services/property.service';
import { UnsubscribableComponent } from '../../classes/abstract/unsubscribable-component';
import { IFilterItem } from '../../interfaces/IFilterItem';
import { ActivatedRoute } from '@angular/router';
import { Chip } from './chip.interface';

/**
 * Reusable filtering component for index pages
 * - Property Filter, Unit Filter, Status Filter (Customizable)
 * - Removeable Filters Chips, Show all/Show less button
 */
@Component({
  selector: 'table-filters-bar',
  templateUrl: './table-filters-bar.component.html',
  styleUrls: ['./table-filters-bar.component.scss']
})
export class TableFiltersBarComponent extends UnsubscribableComponent implements OnInit {
  arrayHelper = new ArrayHelper();

  // From the parent component,
  // pass in flags to enable various filter and count
  filtersCount = 0;
  @Input() enablePropertyFilter = false;
  @Input() enableUnitFilter = false;
  @Input() enableStatusFilter = false;

  @Input() customFilters: {key: string, name: string}[] = [];
  @Input() externalChips: BehaviorSubject<Chip[]> = new BehaviorSubject<Chip[]>([]);

  // calculate widget width based on the filters count
  getFiltersCardWidth(): string {
    switch (this.filtersCount) {
      case 3:
        return '660px';
      case 2:
        return '440px';
      case 1:
        return '230px';
      default:
        return '0';
    }
  }

  // determine styling based on the filters count
  getFiltersStylingClass(): string {
    switch (this.filtersCount) {
      case 3:
        return 'col-sm-12 col-md-4 col-lg-4 text-center';
      case 2:
        return 'col-sm-12 col-md-6 col-lg-6 text-center';
      case 1:
        return 'col-sm-12 col-md-12 col-lg-12 text-center';
      default:
        return 'col-sm-12 col-md-12 col-lg-12 text-center';
    }
  }

  // search bpx form control
  search = new BehaviorSubject('');
  searchFieldControl = new UntypedFormControl('');
  selectedFilter = 't';

  // property filter form control
  propertyList = new BehaviorSubject([]);
  propertyFilterControl = new UntypedFormControl([]);
  propertiesTranspiler = (item) => ({ value: item.id, label: item.name });

  // unit filter form control
  unitList = new BehaviorSubject([]);
  unitFilterControl = new UntypedFormControl([]);
  unitsTranspiler = (item) => ({
    value: item.unit,
    label: item.property_name + " Unit " + item.unit,
    unit: item.unit,
  });

  // status filter form control
  // parent component pass in custom status list
  @Input() statusListInput: string[] = [];
  statusList = new BehaviorSubject([]);
  statusFilterControl = new UntypedFormControl("enabled");
  statusesTranspiler = (item) => ({
    value: item,
    label: item === "all"
      ? "All Statuses"
      : item.charAt(0).toUpperCase() + item.slice(1),
  });

  /**
   * aggregate filters and search box data into a common data source
   * consumed internally and externally
   * consumers:
   * - Used in chips bar UI to display filters selected
   * - Emit to parent component to construct post request payload
   */
  filteredProperties = new BehaviorSubject<Array<number>>([]);
  filteredUnits = new BehaviorSubject<Array<string>>([]);
  generalFilter = new BehaviorSubject<Array<{value: string, filter: string}>>([]);
  filteredStatus = this.enableStatusFilter ? new BehaviorSubject<string>("enabled") : new BehaviorSubject<string>("");
  filtersAggregated = combineLatest([
    this.filteredProperties,
    this.filteredUnits,
    this.generalFilter,
    this.filteredStatus
  ]).pipe(
    map(([properties, units, generalFilter, status]) => {
      const filters = [].concat(
        ...generalFilter.map((filter, i) => ({ id: i, key: filter.filter, value: filter.value })),
        ...properties.map((value, i) => ({ id: i, key: "p", value: value })),
        ...units.map((value, i) => ({ id: i, key: "u", value: value })),
      );

      if(this.enableStatusFilter) {
        filters.push({ id: 0, key: "s", value: status });
      }
      return filters;
    }),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    shareReplay(1)
  );
  @Output() filtersAggregatedOutput = new EventEmitter<IFilterItem[]>();
  @Output() externalChipRemoved = new EventEmitter<Chip>();

  /**
   * construct chips data using the common aggregated data
   * flags to determine UI display mechanism
   * (numbers to show, is exceed one line, show all or hide all)
   */
  @ViewChild('filterContainer') filterContainer!: ElementRef;
  isRemoving = false;
  showAllChips = true;
  isChipsExceedOneLine = false;
  numChipsInFirstLine = 0;
  filtersChipsData: BehaviorSubject<Chip[]> = new BehaviorSubject<Chip[]>([]);

  isChipHidden(index: number) {
    return !this.showAllChips && index >= this.numChipsInFirstLine;
  }

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
  ) {
    super();
    this.setUpFilters();
  }

  // fetch data and set up filters with its observables
  private setUpFilters() {
    this.propertyService.getPropertyList().subscribe((r) => {
      this.propertyList.next(r.data);
    });

    this.propertyFilterControl.valueChanges.subscribe((d) => {
      const propertyUnits = [].concat(
        ...this.propertyList.value
          .filter(i => d.includes(i.id))
          .map(p =>
            this.arrayHelper.removeDuplicates(
              p.property_units.map(u => u.unit)
            ).map(u => ({
              unit: u,
              property_name: p.name,
            }))
          )
      );
      this.unitList.next(propertyUnits);
      this.filteredProperties.next(d);
    });

    this.unitFilterControl.valueChanges.subscribe(d =>
      this.filteredUnits.next(d)
    );

    this.statusFilterControl.valueChanges.subscribe(d => {
        this.filteredStatus.next(d)
      }
    );

    this.searchFieldControl.valueChanges.subscribe(d =>
      this.search.next(d)
    );

    this.filtersAggregated.subscribe(d =>
      this.filtersAggregatedOutput.emit(d)
    );
  }

  ngOnInit() {
    // Count how many active filters
    const filterOptions = [this.enablePropertyFilter, this.enableUnitFilter, this.enableStatusFilter];
    this.filtersCount = filterOptions.filter(option => option).length;

    // Set custom filter value from url query params
    this.sub = this.route.queryParams.pipe(
      take(1)
    ).subscribe(params => {
      const filterItems: { value: string; filter: string; }[] = [];
      this.customFilters.forEach(filter => {
        if (params.hasOwnProperty(filter.key)) {
          const filterItem = {
            filter: filter.key,
            value: params[filter.key]
          };
          filterItems.push(filterItem);
        }
      });
      this.generalFilter.next(filterItems);
    });
  }

  ngAfterViewInit() {
    // set filtersChipsData
    combineLatest([
      this.filtersAggregated,
      this.externalChips
    ]).pipe(
      map(([filterAggregated, externalChips]) => {
        const customKeys = this.customFilters.map(item => item.key);
  
        const t = filterAggregated.filter(i => i.key === "t");
        const otherFilters = filterAggregated.filter(i => customKeys.includes(i.key));
        const hasKeyT = this.customFilters.some(item => item.key === 't'); // prevent duplicate search key
  
        const searchChips: Chip[] = [
          ...(hasKeyT ? [] : t.map((i) => ({ ...i, label: i.value }))),
          ...otherFilters.map((i) => ({
            id: i.id,
            key: i.key,
            label: `${i.value}`,
            value: `${i.value}`,
            filterName: `${this.customFilters.find((f) => f.key === i.key).name}`,
          })),
        ];
  
        const p = filterAggregated.filter(i => i.key === "p");
        const propertiesIds = p.map(i => i.value);
        const propertyChips: Chip[] = this.propertyList.value
          .filter(i => propertiesIds.includes(i.id))
          .map(i => ({
            id: p[propertiesIds.indexOf(i.id)]?.id,
            key: "p",
            label: i.name,
            value: i.id
          }));
  
        const u = filterAggregated.filter(i => i.key === "u");
        const unitsStrings = u.map(i => i.value);
        const unitsChips: Chip[] = this.unitList.value
          .filter(i => unitsStrings.includes(i.unit))
          .map(this.unitsTranspiler)
          .map(i => ({
            id: u[unitsStrings.indexOf(i.unit)]?.id,
            key: "u",
            label: i.label,
            value: i.unit
          }));
  
        const data: Chip[] = [].concat([
          ...searchChips,
          ...propertyChips,
          ...unitsChips,
          ...externalChips,
        ]);
  
        if(this.enableStatusFilter) {
          const s = filterAggregated.filter(i => i.key === "s");
          const statusLabel = this.statusesTranspiler(s[0].value).label
          const statusChip: Chip[] = statusLabel !== "" ? [{
            id: s[0].id,
            key: "s",
            label: statusLabel,
            value: s[0].value
          }] : [];
          data.unshift(...statusChip);
        }
  
        return data;
      }),
    ).subscribe(data => this.filtersChipsData.next(data));

    /**
     * Determine the chips display mechanism
     *
     * the number of chips to display is calculated using the following
     * - chip width = chip label width + adjustment factor (86, determined manually)
     * - container width >= chip width * number of chips
     * - accumulator: accumulate the number of chips until total width exceed container width
     */
    this.filtersChipsData.subscribe(d => {
      const element = this.filterContainer.nativeElement;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = '14px Roboto';

      const chipWidths = d.map(chip => ctx.measureText(chip.label).width + 86);
      const containerWidth = element.clientWidth;
      const { numChipsInFirstLine } = chipWidths.reduce((acc, val, index) => {
        if (acc.accumulatedWidth + val <= containerWidth) {
          acc.accumulatedWidth += val;
          acc.numChipsInFirstLine = index + 1;
        }
        return acc;
      }, { accumulatedWidth: 0, numChipsInFirstLine: 0 });

      this.numChipsInFirstLine = numChipsInFirstLine;
      if (d.length > this.numChipsInFirstLine) {
        if (!this.isRemoving) {
          this.showAllChips = false;
        }
        this.isChipsExceedOneLine = true;
      } else {
        this.isChipsExceedOneLine = false;
      }
      this.isRemoving = false;
    });
  }

  // populate filter from parent component
  ngOnChanges(changes: SimpleChanges) {
    if ('statusListInput' in changes) {
      const statusListChange = changes.statusListInput.currentValue;
      this.statusList.next(statusListChange);
    }
  }

  handleSearch() {
    const searchValue = this.search.getValue();

    if(searchValue.length > 0){
      // Filter out any filters with the same filter name so don't double search same filter
      const filteredFilters = this.generalFilter.value.filter(
        filter => filter.filter !== this.selectedFilter
      );

      this.generalFilter.next([
        ...filteredFilters,
        {value: searchValue.toLowerCase(), filter: this.selectedFilter}
      ]);
    }

    this.search.next("");
    this.searchFieldControl.patchValue("");
  }

  /**
   * Clear the form control for selected filter
   * Form control is the root data source,
   * Ckearing form conrol will clear both filter selection and data display
   *
   * @param filter the target filter to clear
   */
  handleRemoveFilter(filter: Chip) {
    this.isRemoving = true;
    const customKeys = this.customFilters.map(item => item.key);
    const filtersChipsData = this.filtersChipsData.getValue();
    switch (filter.key) {
      case "s":
        this.statusFilterControl.patchValue("");
        break;
      case "t":
        this.generalFilter.next(
          filtersChipsData
            .filter(i => (i.key === "t" || customKeys.includes(i.key)) && i.id != filter.id)
            .map(i => {return {value: i.value, filter: i.key}})
        );
        break;
      case "p":
        this.propertyFilterControl.patchValue(
          filtersChipsData
            .filter(i => i.key === "p" && i.id != filter.id)
            .map(i => i.value)
        )
        break;
      case "u":
        this.unitFilterControl.patchValue(
          filtersChipsData
            .filter(i => i.key === "u" && i.id != filter.id)
            .map(i => i.value)
        )
        break;
      case "external":
        this.externalChips.next(
          filtersChipsData
            .filter(i => (i.key === "external" ) && i.id != filter.id)
        );
        this.externalChipRemoved.emit(filter);
        break;
      default: // 
        if(customKeys.includes(filter.key)) {
          this.generalFilter.next(
            filtersChipsData
              .filter(i => (i.key === "t" || customKeys.includes(i.key)) && i.id != filter.id)
              .map(i => {return {value: i.value, filter: i.key}})
          );
        }
        break;
    }
  }

  handleClearFilters(): void {
    this.selectedFilter = 't'; 
    this.statusFilterControl.patchValue("");
    this.generalFilter.next([]);
    this.propertyFilterControl.patchValue([]);
    this.unitFilterControl.patchValue([]);

    this.externalChips.value.forEach(value => {
      if (value.value != 'today' && value.value != 'new') // today and new will automatically be removed when filters are cleared
        this.externalChipRemoved.emit(value)
    });
    this.externalChips.next([]);
  }
}
