import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { UnsubscribableComponent } from 'src/app/shared/classes/abstract/unsubscribable-component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PqValidators } from 'src/app/shared/validators/pq.validators';
import { PasswordService, ResetPasswordData } from '../password.service';
import { Branding } from 'src/app/shared/interfaces/branding';
import { State } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { selectAppBranding } from 'src/app/store/app.selectors';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialogComponent } from 'src/app/shared/components/terms/terms-dialog/terms-dialog.component';
import { PrivacyPolicyComponent } from 'src/app/shared/components/terms/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent extends UnsubscribableComponent implements OnInit, OnDestroy {
  public branding: Branding;

  formGroup = new FormGroup({
    email: new FormControl<string>(null, [Validators.required, Validators.email]),
    password: new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(6),
      ]
    ),
    password_confirmation: new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(6),
        PqValidators.notSame('password'),
      ]
    ),
    token: new FormControl(''),
    terms: new FormControl(false, [Validators.requiredTrue]),
  });

  showPassword = new BehaviorSubject(false);
  showPasswordConfirmation = new BehaviorSubject(false);
  disableButton = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<State>,
    public activatedRoute: ActivatedRoute,
    private passwordService: PasswordService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);

    this.sub = this.activatedRoute.params.subscribe(
      p => {
        if (p.token) {
          this.formGroup.controls['token'].setValue(p.token);
        }
      }
    );

    this.formGroup.get('password')?.valueChanges.subscribe(() => {
      this.formGroup.get('password_confirmation')?.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  send() {
    this.formGroup.markAllAsTouched();
    if (! this.formGroup.valid) {
      this.toastr.error(this.translate.instant('Validation error'))
      return;
    }

    this.passwordService.reset(this.formGroup.value as ResetPasswordData).subscribe({
      next: r => {
        this.toastr.success(
          undefined,
          this.translate.instant('Password has been changed successfully')
        );
        this.router.navigate(['/n/login']);
      },
      error: (d: HttpErrorResponse) => {
        const errorMessage =
          d.error?.password?.[0] ??
          this.translate.instant('Unable to set new password');

        this.toastr.error(d.error?.message, errorMessage);
      },
    })
  }

  toggleShowPassword() {
    this.showPassword.next(!this.showPassword.getValue());
  }

  toggleShowPasswordConfirmation() {
    this.showPasswordConfirmation.next(!this.showPasswordConfirmation.getValue());
  }

  openTerms() {
    this.dialog.open(TermsDialogComponent, {
      width: '600px'
    });
  }

  openPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyComponent, {
      width: '600px'
    });
  }

}
