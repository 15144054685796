import { Company } from '../interfaces/company';
import {CompanyChartData} from '../interfaces/company-chart-data';

export interface CompaniesState {
  companies: Array<Company>;
  editedCompany: Company | null;
  companyChart: CompanyChartData;
}

export const initialCompaniesState: CompaniesState = {
  companies: [],
  editedCompany: null,
  companyChart: {
    approvedTransactions: [],
    paidTransactions: [],
    usersUse: [],
    paymentMethods: [],
    ticks: []
  }
};
