<div *ngIf="!isEmbedded" class="container" style="margin-bottom:30px">
  <div class="row">
    <div class="col-xs-12 align-center">
      <div class="companyicon">
        <img src="{{branding.logo_file_url}}"/>
      </div>
    </div>
  </div>
</div>


<section class="wrapper">
  <h1 class="text-center">Two Factor Authentication Required</h1>
  <p class="text-center">Your company requires you to enable two factor authentication.<br>
    You have not set it up yet. Please take a minute to set up it now.</p>
  <div class="instructions ">
    <div>
      <h3>Step by step instructions:</h3>
      <ol>
        <li>Download an Authentication App (e.g Google Auth)</li>
        <li>In The App create a new entry</li>
        <li>Scan provided QR code</li>
        <li>Enter the app generated 6 digits below</li>
      </ol>
    </div>
    <div>
      <img [src]="imagePath">
    </div>
  </div>
<form>
  <div class="row input">
    <input [(ngModel)]="code"
           numbers-only
           required
           autocomplete="one-time-code"
           name="code"
           placeholder="6 digits code" minlength="6" maxlength="6"  class="form-control">
  </div>

  <div class="row actions">
    <div class="col-xs-12 col-md-3">

      <div class="button">
        <button (click)="logout()" type="button" class="btn btn-danger">Log out</button>
      </div>

    </div>
    <div class="col-xs-12 col-md-6"></div>

    <div class="col-xs-12 col-md-3">

      <div class="button ">
        <button (click)="enable()" [disabled]="isLoading" type="submit" class="btn btn-success">Enable</button>
      </div>

    </div>

  </div>
</form>
</section>
