import { Component, OnInit } from "@angular/core";
import { TermsService } from "../terms.service";
import { CommonModule } from "@angular/common";
import { LayoutService } from "../../../services/layout.service";
import { SharedModule } from "../../../shared.module";

@Component({
  selector: "app-privacy-policy",
  standalone: true,
  imports: [
    CommonModule, 
    SharedModule
  ],
  templateUrl: "./privacy-policy.component.html",
  styleUrl: "./privacy-policy.component.scss",
})
export class PrivacyPolicyComponent implements OnInit {
  public isCordova = !!window.cordova;

  padAgreementBody: string = "";
  lastUpdatedOn: string | null = null;

  constructor(
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
  }

  printOpen() {
    this.layoutService.isPrint.next(true);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  }

  printClose() {
    this.layoutService.isPrint.next(false);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  }
}
