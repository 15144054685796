import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {JWTService} from "./shared/services/JWT.service";
import {catchError, switchMap} from "rxjs/operators";
import {logout} from "./store/app.actions";
import {Store} from "@ngrx/store";
import {State} from "./store/app.state";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtService: JWTService,
              private store: Store<State>,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const publicURLs = ['/api/v1/users/login'];
    // @ts-ignore
    if (!Boolean(window.cordova)) {
      return next.handle(req);
    }

    for (const publicURL of publicURLs) {
      if (req.url.includes(publicURL)) {
        const headers = req.headers.append('Content-Type', 'application/json');
        const requestClone = req.clone({
          headers
        });
        return next.handle(requestClone);
      }
    }

    return from(this.jwtService.getToken())
      .pipe(
        switchMap(token => {
          const headers = req.headers
            .set('Authorization', 'Bearer ' + token)
            .append('Content-Type', 'application/json');
          const requestClone = req.clone({
            headers
          });
          return next.handle(requestClone).pipe(
            catchError(error => this.handleUnauthorized(error))
          );
        })
      );
  }

  private handleUnauthorized(error: HttpErrorResponse): Observable<never> {
    if ((error.status === 403 || error.error.message === 'Unauthenticated.') && error.error.message !== 'junior_manager can\'t access this') {
      console.log('got logged out', error);
      /**
       * At least on iOS, it stores cookies, so a full logout process is needed.
       *
       * Apart from that, it also takes a long time to show the login form (~5-7 seconds).
       */
      this.store.dispatch(logout());
    }

    return throwError(error);
  }
}
