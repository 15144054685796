import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { UnsubscribableComponent } from 'src/app/shared/classes/abstract/unsubscribable-component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PasswordService } from '../password.service';
import { Branding } from 'src/app/shared/interfaces/branding';
import { selectAppBranding } from 'src/app/store/app.selectors';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/app.state';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent extends UnsubscribableComponent implements OnInit, OnDestroy {
  public branding: Branding;

  formGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  disableButton = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private passwordService: PasswordService
  ) {
    super()
  }

  ngOnInit(): void {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  send() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.toastr.error(this.translate.instant('Validation error'))
      return;
    }

    this.disableButton.next(true)

    this.passwordService.remind(this.formGroup.get('email')?.value).subscribe({
      next: (r) => {
        if (r.success) {
          this.toastr.success(r.message, this.translate.instant('Success'));
          this.disableButton.next(false);
          
          window.location.href = '/#/n/login';
        } else {
          this.toastr.error(r.message, this.translate.instant('Error'));
          this.disableButton.next(false);
        }

      },
      error: (r) => {
        this.toastr.error(r.message, this.translate.instant('Error'));
        this.disableButton.next(false);
      },
    })
  }

}
