import moment from 'moment';
import { MessageAttachmentModel } from "./message-attachment.model";
import { Ticket } from "./ticket.interface";
import {Recipient} from "../../../shared/interfaces/recipient";

export class MessageModel {

    get bodyPreview(): string {
        return this.body
            ? this.body.replace(/<[^>]*>/g, " ").replace("\n", "<br>") // this only replaces the first new line with <br> which is fine for a 2 line preview
            : "";
    }

    get timeAgo(): string | Date {
        if (this.created_at) {
            return moment(this.created_at).isSame(moment(), "day") // sent today
                ? moment(this.created_at).toNow(true)
                : moment(this.created_at).isSame(moment(), "year") // sent this year
                    ? moment(this.created_at).format("MMM DD")
                    : moment(this.created_at).format("MMM DD, YYYY");
        }
        return "";
    }

    public id: number;

    public from_id?: number;
    public to_id?: number;
    public from_name?: string;
    public to_name?: string;
    public from_recipient?: Recipient;

    public copied_to: { full_name: string }[];

    public subject?: string;
    public body?: string;

    public suggested_response_text?: string;
    public parent_id?: number;
    public ticketing_enabled?: boolean;

    public is_read?: boolean;
    public is_opened?: boolean;

    public opened_by_email?: boolean;
    public opened_by_portal?: boolean;

    public created_at?: string;
    public type?: string;
    public archived?: boolean;

    public attachments?: MessageAttachmentModel[];

    public children?: MessageModel[];
    public ticket?: Ticket;

    public count_id?: number;
    public count?: Count;

    public prospect_id?: number;

    constructor(initialData: Partial<MessageModel> = {}) {
        Object.assign(this, initialData);
    }
}

export interface Count {
    id: number,
    missing: number;
    delivered: number;
    recipient_type: string;
}
