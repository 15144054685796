import { Action, ActionReducer, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { AppState, initialAppState, State } from './app.state';
import * as actions from './app.actions';

// Import initial states
import { initialCompaniesState } from '../modules/companies/store/companies.state';
import { initialManagersState } from '../modules/managers/store/managers.state';
import { initialPropertiesState } from '../modules/properties/store/properties.state';
import { initialLeaseApplicationsState } from '../modules/lease-applications/store/lease-applications.state';
import { initialMessagesState } from '../modules/messages/store/messages.state';
import { initialDashboardState } from '../modules/dashboard/store/dashboard.state';

// Import reducers
import { companiesReducer } from '../modules/companies/store/companies.reducers';
import { managersReducer } from '../modules/managers/store/managers.reducers';
import { propertiesReducer } from '../modules/properties/store/properties.reducer';
import { dashboardReducer } from '../modules/dashboard/store/dashboard.reducers';
import { leaseApplicationsReducer } from '../modules/lease-applications/store/lease-applications.redusers';
import { messagesReducer } from '../modules/messages/store/messages.redusers';

/** 
 * Application-level reducer 
 */
const _appReducer = createReducer(
  initialAppState,
  on(actions.setUser, (state, {user}) => ({...state, user})),
  on(actions.setBranding, (state, {branding}) => ({...state, branding})),
  on(actions.setTranslations, (state, {translations}) => ({...state, translations})),
  on(actions.setLocale, (state, { locale }) => ({ ...state, locale: locale || 'en' })),
  on(actions.setCurrentPage, (state, {currentPage}) => ({...state, currentPage})),
);

export function appReducer(state: AppState | undefined, action: Action) {
  return _appReducer(state, action);
}

/**
 * Meta-reducer to reset sensitive states on logout or session reset
 */
export function resetSensitiveStatesMetaReducer(reducer: ActionReducer<State, Action>): ActionReducer<State, Action> {
  return (state: State | undefined, action: Action): State => {
    if (action.type === actions.clearSensitiveStates.type) {
      state = {
        app: {
          ...initialAppState,
          branding: state?.app?.branding,  // Preserve branding
          currentPage: 'Dashboard',
          translations: state?.app?.translations || {}, // Preserve translations
          locale: state?.app?.locale || 'en', // Preserve locale
        },
        companies: initialCompaniesState,
        managers: initialManagersState,
        properties: initialPropertiesState,
        'lease-applications': initialLeaseApplicationsState,
        messages: initialMessagesState,
        dashboard: initialDashboardState,
      };
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [resetSensitiveStatesMetaReducer];

/** 
 * Combine all feature reducers 
 */
export const reducers: ActionReducerMap<State> = {
  app: appReducer,
  companies: companiesReducer,
  managers: managersReducer,
  properties: propertiesReducer,
  'lease-applications': leaseApplicationsReducer,
  messages: messagesReducer,
  dashboard: dashboardReducer,
};
