import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  DoCheck,
  SimpleChanges,
  IterableDiffer,
  IterableDiffers,
  IterableChanges,
  ViewChild,
  AfterViewInit,
  EventEmitter, Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {SelectOption} from '../../interfaces/select-option';
import {UnsubscribableComponent} from '../../classes/abstract/unsubscribable-component';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent extends UnsubscribableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, DoCheck {
  @ViewChild('matSelect') ref: MatSelect;

  @Input() control: FormControl<(string | number | { [key: string]: any; })[]>;
  @Input() options: any[];
  @Input() label: string = null;
  @Input() open: boolean;
  @Input() transpiler: (item: any) => SelectOption;

  /** optional parameter for O(1) label lookup - see getSelectedOptionLabel */
  @Input() labelKey: string;

  @Input() useMoreLabel = false;
  @Input() allSelectedLabel = '';

  @Input() inline = false;

  @Output() selectionChange = new EventEmitter<(string | number | { [key: string]: any; })[]>();  // Add this line


  public transpilingOptions: Array<SelectOption> = [];
  public filteredOptions: Array<SelectOption> = [];
  public searchControl: FormControl<string> = new FormControl<string>('');
  get allOptionsSelected(): boolean {
    return this.control.value?.length === this.options.length;
  }

  private _diff: IterableDiffer<number>;

  constructor(
    private _iterableDiffers: IterableDiffers,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.searchControl.valueChanges
      .subscribe(() => {
        this.filterItems();
      });

    this._diff = this._iterableDiffers.find(this.options).create();

    this.control.valueChanges.subscribe(value => {  // Add this block
      this.selectionChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngDoCheck(): void { //use instead of ngOnChanges for when array is mutated.
    const changes: IterableChanges<number> = this._diff.diff(this.options); //check if new options have been added

    if (changes) {
      this.transpilingOptions = this.options.map(this.transpiler);
      this.filterItems();
    }
  }

  toggleSelectAll(selectAllValue: boolean) {
    if (selectAllValue) {
      this.control.patchValue(this.filteredOptions.map(item => item.value));
    } else {
      this.control.patchValue([]);
    }
    this.selectionChange.emit(this.control.value);  // Add this line
  }

  getSelectedOptionLabel(value: any): string {
    if (value) {
      /** for O(1) time efficiency at lookup - if <value> is an object it will contain
       * the label as key (which is just labelKey) */
      if (typeof value === 'object' && this.labelKey && value[this.labelKey]) {
        return value[this.labelKey];
      }

      if (typeof value === 'object' && value.charge_code) {
        return this.transpilingOptions.find(o => o.value['charge_code'] === value.charge_code)?.label;
      }

      return this.transpilingOptions.find(o => o.value.toString() === value.toString())?.label;
    }
    return '';
  }

  protected filterItems() {
    if (!this.transpilingOptions.length) {
      return;
    }
    let search = this.searchControl.value;
    if (!search) {
      this.filteredOptions = this.transpilingOptions.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptions = this.transpilingOptions.filter(item => item.label.toLowerCase().indexOf(search) > -1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngAfterViewInit(): void {
    if (this.open) {
      this.ref.open(); // Replace 'open' with the actual method name
    }
  }

}
