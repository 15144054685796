import {createAction, props} from '@ngrx/store';
import {Company} from '../interfaces/company';
import {CompanyChartData} from '../interfaces/company-chart-data';

export enum CompaniesActions {
  CREATE_COMPANY = '[Companies] Create company',
  COMPANY_CREATED = '[Companies] Company was created',
  LOAD_COMPANIES = '[Companies] Load companies',
  COMPANIES_LOADED = '[Companies] Set loaded companies',
  LOAD_COMPANY_CHARTS = '[Companies] Load company charts',
  COMPANY_CHARTS_LOADED = '[Companies] Set loaded charts for company',
}

export const createCompany = createAction(CompaniesActions.CREATE_COMPANY, props<{ company: Company }>());
export const companyCreated = createAction(CompaniesActions.COMPANY_CREATED, props<{ company: Company }>());
export const loadCompanies = createAction(CompaniesActions.LOAD_COMPANIES);
export const companiesLoaded = createAction(CompaniesActions.COMPANIES_LOADED, props<{ companies: Company[] }>());
export const loadCompanyCharts = createAction(CompaniesActions.LOAD_COMPANY_CHARTS, props<{ companyId: number }>());
export const companyChartsLoaded = createAction(CompaniesActions.COMPANY_CHARTS_LOADED, props<{ data: CompanyChartData }>());
