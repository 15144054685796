import {Injectable} from '@angular/core';
import { JWTServiceInterface } from './JWT/JWT-service.interface';
import { JWTNativeService } from './JWT/JWT-native.service';
import { JWTWebService } from './JWT/JWT-web.service';
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class JWTService implements JWTServiceInterface {
  private JWTServiceObject = null;
  private isCapacitor = Capacitor.isNativePlatform();

  constructor() {
    if(!this.isCapacitor){
      this.JWTServiceObject = new JWTWebService();
    } else {
      this.JWTServiceObject = new JWTNativeService();
    }
  }

  async getToken() {
    return this.JWTServiceObject.getToken();
  }

  setToken(token = null, type = 'Bearer', expired = 0) {
    return this.JWTServiceObject.setToken(token, type, expired);
  }

  removeToken() {
    return this.JWTServiceObject.removeToken();
  }
}
