import {createAction, props} from '@ngrx/store';
import {AnalyticsFilter} from '../interfaces/analytics-filter';

export enum LeaseApplicationsActions {
  LOAD_NEW_CHART = '[Lease Applications Analytics] Load new chart',
  SET_NEW_CHART = '[Lease Applications Analytics] Set new chart',
  LOAD_SOURCES_CHART = '[Lease Applications Analytics] Load sources chart',
  SET_SOURCES_CHART = '[Lease Applications Analytics] Set sources chart',
  LOAD_TIME_LAPS_CHART = '[Lease Applications Analytics] Load time laps chart',
  SET_TIME_LAPS_CHART = '[Lease Applications Analytics] Set time laps chart',
  LOAD_CONVERSIONS_CHART = '[Lease Applications Analytics] Load conversions chart',
  SET_CONVERSIONS_CHART = '[Lease Applications Analytics] Set conversions chart',
}

export const loadNewChart = createAction(LeaseApplicationsActions.LOAD_NEW_CHART, props<{ filter: AnalyticsFilter }>());
export const setNewChart = createAction(LeaseApplicationsActions.SET_NEW_CHART, props<{ data: any[] }>());
export const loadSourcesChart = createAction(LeaseApplicationsActions.LOAD_SOURCES_CHART, props<{ filter: AnalyticsFilter }>());
export const setSourcesChart = createAction(LeaseApplicationsActions.SET_SOURCES_CHART, props<{ data: any[] }>());
export const loadTimeLapsChart = createAction(LeaseApplicationsActions.LOAD_TIME_LAPS_CHART, props<{ filter: AnalyticsFilter }>());
export const setTimeLapsChart = createAction(LeaseApplicationsActions.SET_TIME_LAPS_CHART, props<{ data: any[] }>());
export const loadConversionsChart = createAction(LeaseApplicationsActions.LOAD_CONVERSIONS_CHART, props<{ filter: AnalyticsFilter }>());
export const setConversionsChart = createAction(LeaseApplicationsActions.SET_CONVERSIONS_CHART, props<{ data: any[] }>());
