import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {from, Observable, of} from "rxjs";
import {ApiResponse} from "src/app/shared/interfaces/api-response";
import {environment} from "src/environments/environment";
import {IFilteredInspections} from "./interfaces/IFilteredInspections";
import {Preferences} from '@capacitor/preferences';
import {Network} from "@capacitor/network";
import {map, switchMap} from "rxjs/operators";
import {OfflineService} from "../../offline.service";

@Injectable({
  providedIn: 'root'
})
export class InspectionsService {

  constructor(
    private http: HttpClient,
    public offlineService: OfflineService
  ) {}

  createTemplate(template: any): Observable<any> {
    return this.http.post<Observable<any>>(`${environment.domain}/api/v1/inspection-template`, template);
  }

  editTemplate(template: any) : Observable<any>{
    return this.http.patch<Observable<any>>(`${environment.domain}/api/v1/inspection-template/${template.id}`, template);
  }

  deleteTemplate(template: any) : Observable<any>{
    return this.http.delete<Observable<any>>(`${environment.domain}/api/v1/inspection-template/${template.id}`);
  }

  getTemplate(id: any): Observable<any> {
    if (+id) {
      return this.http.get<any>(`${environment.domain}/api/v1/inspection-template/${id}`).pipe(
        map(response => response.data)
      );
    } else {
      return of({
        name: '',
        description: '',
        company_id: '',
        areas: []
      });
    }
  }

  public getInspections(data: any, recurring: boolean = false): Observable<ApiResponse<IFilteredInspections>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (!status.connected) {
          return from(this.getCachedInspections()).pipe(
            switchMap(cachedData => {
              if (cachedData) {
                return of(cachedData);
              } else {
                return of(null);
              }
            })
          );
        } else {
          return this.http.post<ApiResponse<IFilteredInspections>>(`${environment.domain}/api/v1/inspection/filter?recurring=${recurring ? 'true' : 'false'}`, data);
        }
      })
    );
  }

  // requests
  public getInspection(id): Observable<ApiResponse<any>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (!status.connected) {
          return from(Preferences.get({ key: `cachedInspection-${id}` })).pipe(
            switchMap(cachedData => {
              if (cachedData.value) {
                return of(JSON.parse(cachedData.value));
              } else {
                return of(null);
              }
            })
          );
        } else {
          return this.http.get<ApiResponse<any>>(`${environment.domain}/api/v1/inspection/${id}`);
        }
      })
    );
  }

  public updateInspection(inspection): Observable<ApiResponse<any>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (!status.connected) {
          const offlineInspection = {
            ...inspection,
            updated_at: new Date(),
          };

          Preferences.get({key: 'cachedInspections'}).then(
            response => {
              const buffer = JSON.parse(response.value);
              const index = buffer.data.inspections.findIndex((i: any) => i.id === inspection.id);
              buffer.data.inspections[index].status = inspection.status;
              Preferences.set({
                key: 'cachedInspections',
                value: JSON.stringify(buffer)
              });
            });

          return from(Preferences.set({
            key: `cachedInspection-${inspection.id}`,
            value: JSON.stringify(offlineInspection)
          })).pipe(
            switchMap(() => of({ success: true, message: 'Data saved to cache' } as any))
          );
        } else {
          return this.http.patch<ApiResponse<any>>(`${environment.domain}/api/v1/inspection/${inspection.id}`, inspection);
        }
      })
    );
  }

  public getTemplateEdits(pageIndex: number, pageSize: number): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${environment.domain}/api/v1/inspection-template/edit-history?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }

  public getTemplates(payload): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/inspection-template/list`, payload);
  }

  public getInspectionTemplates(): Observable<ApiResponse<any>> {
    return from(Network.getStatus()).pipe(
      switchMap(status =>
        status.connected
          ? this.http.get<ApiResponse<any>>(`${environment.domain}/api/v1/inspection-template`)
          : from(this.getCachedInspectionTemplates()).pipe(
            switchMap(cachedData => of(cachedData || null))
          )
      )
    );
  }

  public createInspection(data: any): Observable<any> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (!status.connected) {
          return from(this.safeOffline(data)).pipe(
            switchMap(() => of({ success: true, message: 'Data saved to buffer' } as any))
          );
        } else {
          return this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/inspection`, data);
        }
      })
    );
  }

  /**
   * OFFLINE
   */
  cacheInspections(inspections_list, inspections_single) {
    Preferences.set({
      key: 'cachedInspections',
      value: JSON.stringify(inspections_list)
    });

    inspections_single.forEach((inspection: any) => {
      Preferences.set({
        key: `cachedInspection-${inspection.id}`,
        value: JSON.stringify(inspection)
      });
    })
  }

  cacheInspectionTemplates(inspections_templates) {
    Preferences.set({
      key: 'cachedInspectionTemplates',
      value: JSON.stringify({data: inspections_templates})
    });
  }

  async getCachedInspections(): Promise<ApiResponse<IFilteredInspections> | null> {
    const { value } = await Preferences.get({ key: 'cachedInspections' });
    return value ? JSON.parse(value) : null;
  }
  async getCachedInspectionTemplates(): Promise<ApiResponse<IFilteredInspections> | null> {
    const { value } = await Preferences.get({ key: 'cachedInspectionTemplates' });
    return value ? JSON.parse(value) : null;
  }

  private async safeOffline(data: any): Promise<void> {
    const { value } = await Preferences.get({ key: 'cachedInspections' });

    console.log('safeOffline', data);
    const inspection = {
      ...data,
      id: data.offlineId,
      is_offline: true,
      property: data.offline_property_name,
      status: 'Scheduled',
      name: data.offline_unit_owner,
      unit: data.offline_unit_number,
      date: data.date.toLocaleDateString('en-CA'),
      description: data.offline_description
    }
    if (value) {
      const buffer = JSON.parse(value);
      buffer.data.inspections.push(inspection);
      await Preferences.set({
        key: 'cachedInspections',
        value: JSON.stringify(buffer)
      });
    } else {
      await Preferences.set({
        key: 'cachedInspections',
        value: JSON.stringify([inspection])
      });
    }
  }

}
