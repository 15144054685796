import { DataItem, SeriesItem } from "../interfaces/chart-data";
export interface LeaseApplicationsState {
  newChartData: DataItem[];
  sourcesChartData: DataItem[];
  timeLapsChartData: SeriesItem[];
  conversionChartData: SeriesItem[];
}

export const initialLeaseApplicationsState: LeaseApplicationsState = {
  newChartData: [],
  sourcesChartData: [],
  timeLapsChartData: [],
  conversionChartData: []
};
