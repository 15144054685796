import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { loadUser } from '../../store/app.actions';
import { ANGULAR_JS_EVENTS } from '../../shared/constants';
import { selectAppBranding } from '../../store/app.selectors';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AngularJsService } from '../../shared/services/angular-js.service';
import { JWTService } from '../../shared/services/JWT.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/app.state';
import { UnsubscribableComponent } from '../../shared/classes/abstract/unsubscribable-component';
import { environment } from '../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import { ApiResponse } from "../../shared/interfaces/api-response";
import { TranslateService } from '@ngx-translate/core';

declare var angular;

interface GetTranslationResponse {
    translations: object;
    current_locale: 'en' | 'fr';
}

@Injectable({
    providedIn: 'root'
})
export class LoginService extends UnsubscribableComponent {
    errors: any;
    public db: any = null;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router,
        protected angularJsService: AngularJsService,
        private jwtService: JWTService,
        protected angularJs: AngularJsService,
        private store: Store<State>,
        private t: TranslateService
    ) {
        super();
    }

    public login(authData) {
        return this.http.post(`${environment.domain}/api/v1/users/login`, authData).pipe(
            tap(() => authData.remember_me === true ? this.setRememberMe(authData) : this.removeRememberMe()),
            map((data: ApiResponse<any>) => {
              if (data.success === true && data.data['2_fa_install_required']) {
                 data.data = 'OPT_INSTALLATION_REQUIRED';
                return data;
              }
              return data;
            }),
            tap((data: ApiResponse<any>) => {
              if (data.data === 'OPT_INSTALLATION_REQUIRED') {
                return;
              }

                if (data.success === true) {
                    if (typeof data.data.redirect_to_new_portal !== 'undefined' && data.data.redirect_to_new_portal) {
                        this.toastr.info(this.t.instant('Please note you are being redirected to the new portal interface where you will need to login again.'));
                        setTimeout(function () {
                            window.location.replace(data.data.redirect_to_new_portal);
                        }, 3000);
                        return;
                    }
                    if (data.data.password_force_reset && data.data.password_force_reset_token) {
                        this.toastr.error(this.t.instant('Your password has not been updated for a long time. Please change your password'));
                        window.location.href = `/#/password/reset/${data.data.password_force_reset_token}`;
                        return;
                    }
                    if (data.data.log_in_forbidden) {
                        this.toastr.error(this.t.instant('Only Applicants are Allowed'));
                        return;
                    }
                    if (data.data.redirect_to_lease_portal) {
                      this.toastr.info(data.message);
                      setTimeout(() => {
                        window.open(data.data.redirect_to_lease_portal, '_blank').focus();
                      }, 3000);
                      return;
                    }

                    const locale = data.data.locale ? data.data.locale : 'en';

                    this.jwtService.setToken(data.data.access_token, data.data.token_type, data.data.expired_in);

                    this.store.dispatch(loadUser());

                    this.angularJs.fireEvent(ANGULAR_JS_EVENTS.USER_LOADED, data);


                    if (data.data.needs_email !== undefined) {
                        window.location.href = `/#/email`;
                    } else {
                        this.sub = this.store.select(selectAppBranding).subscribe(branding => {

                            const postLoginUrl = angular.element(document.getElementById('angularjs-container')).parent().scope().$root.postLogInRoute;

                            if (postLoginUrl) {
                                console.error('postLoginUrl found', postLoginUrl, '/#' + postLoginUrl.replace('?preventRedirect', ''));
                                if (postLoginUrl.includes('/n/')) {

                                    const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
                                    angularJsScope.$state.go('main.ng2', {}, { reload: false });

                                    this.router.navigateByUrl(postLoginUrl.replace('?preventRedirect', '')).then(r => {

                                        const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
                                        if (angularJsScope) {
                                            angularJsScope.$state.go('main.ng2', {}, { notify: false });
                                        } else {
                                            console.error('Where is angular scope???', angularJsScope);
                                        }
                                    });
                                } else {
                                    window.location.href = '/#' + postLoginUrl;
                                }
                            } else {
                                if (data.data.permissions === 'applicant') {
                                    window.location.href = `/#/lease-application/dashboard`;
                                } else {
                                    if (
                                        data.data.permissions === 'payquad_admin'
                                        || data.data.permissions === 'junior_manager'
                                        || data.data.permissions === 'admin_manager'
                                    ) {
                                        const url = data.data['2_fa_enabled'] ? '/n/2fa' : '/n/dashboard';
                                        this.router.navigateByUrl(url).then(r => {
                                            const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
                                            if (angularJsScope) {
                                                angularJsScope.$state.go('main.ng2', {}, { notify: false });
                                            } else {
                                                console.error('Where is angular scope???', angularJsScope);
                                            }
                                        });
                                    } else {
                                        window.location.href = `/#/dashboard`;
                                    }
                                }
                            }
                        });
                    }
                }
            })
        );
    }

    setRememberMe(authData) {
        localStorage.setItem('email', authData.email);
        localStorage.setItem('remember_me', '1');

        // @ts-ignore
        if (window.cordova && this.db) {
            this.db.transaction(function (tx) {
                tx.executeSql('UPDATE payquad_config SET remember_me=?, remember_email=?', ['true', authData.email], function (res) {
                }, function (error) {
                    console.log('UPDATE ERROR: ' + error.message);
                });
            });
        }
    }

    removeRememberMe() {
        localStorage.removeItem('email');
        localStorage.removeItem('remember_me');

        // @ts-ignore
        if (window.cordova && this.db) {
            this.db.transaction(function (tx) {
                tx.executeSql('DELETE FROM payquad_config WHERE key = ?', ['remember_me'], function (res) {
                }, function (error) {
                    console.log('DELETE ERROR: ' + error.message);
                });
                tx.executeSql('DELETE FROM payquad_config WHERE key = ?', ['remember_email'], function (res) {
                }, function (error) {
                    console.log('DELETE ERROR: ' + error.message);
                });
            });
        }
    }

    verifyOTP(code): Observable<ApiResponse<any>> {
     return  this.http.post<ApiResponse<any>>(`${environment.domain}/api/v1/users/login/verify-otp`, {code: code});
    }

  getOptQR(): Observable<any> {
    return this.http.get<any>(environment.domain+ '/api/v1/users/opt-qr');
  }

  enable(code: string) {
    return this.http.post<any>(environment.domain+ '/api/v1/users/verify-otp', { code: code });

  }
}


